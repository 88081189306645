import { useEffect, useState } from "react";
import { useErrorBoundary } from "react-error-boundary";
import { matchPath, Route, Routes, useLocation, useNavigate } from "react-router-dom"; // https://reactrouter.com/web/guides/quick-start
import { Slide, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./App.scss"; // Global scss file
import { cAnalyticsRouteType, cRouteType, CUSTOMER_CODE, WALK_RETURN_URL } from "./app/constants";
import { useAppDispatch, useAppSelector, useQuery } from "./app/hooks";
import Icon, { EIcon } from "./components/Icon/Icon";
import NavBarContainer from "./components/NavBarContainer/NavBarContainer";
import SpinnerBackdrop from "./components/SpinnerBackdrop/SpinnerBackdrop";
import SupportContainer from "./components/SupportContainer/SupportContainer";
import AdminProvider from "./hoc/AdminProvider/AdminProvider";
import AuthProvider from "./hoc/AuthProvider/AuthProvider";
import { postConfig, selectIsConfigInitialised } from "./modules/customerSlice";
import { getLogout, getUserDetails, selectIsLoggedIn, selectResumeWalk, updateRedirectUrl } from "./modules/userSlice";
import { selectWalkIsActive } from "./modules/walkSlice";
import AdminContainer from "./pages/AdminContainer/AdminContainer";
import CustomExportContainer from "./pages/AnalyticsContainer/Analytics/CustomExportContainer/CustomExportContainer";
import DocumentAnalyticsContainer from "./pages/AnalyticsContainer/Analytics/DocumentAnalyticsContainer/DocumentAnalyticsContainer";
import GraphReportContainer from "./pages/AnalyticsContainer/Analytics/GraphReportContainer/GraphReportContainer.lazy";
import MatterAnalyticsContainer from "./pages/AnalyticsContainer/Analytics/MatterAnalyticsContainer/MatterAnalyticsContainer";
import MatterCustomExportContainer from "./pages/AnalyticsContainer/Analytics/MatterCustomExportContainer/MatterCustomExportContainer";
import PackagedReportContainer from "./pages/AnalyticsContainer/Analytics/PackagedReportContainer/PackagedReportContainer";
import AnalyticsContainer from "./pages/AnalyticsContainer/AnalyticsContainer.lazy";
import DocumentInformationModalContainer from "./pages/DocumentsContainer/Documents/DocumentInformationModalContainer/DocumentInformationModalContainer";
import DocumentsContainer from "./pages/DocumentsContainer/DocumentsContainer.lazy";
import InterviewLogContainer from "./pages/InterviewLogContainer/InterviewLogContainer";
import LoginContainer from "./pages/LoginContainer/LoginContainer.lazy";
import MatterContainer from "./pages/MatterContainer/MatterContainer";
import MattersContainer from "./pages/MattersContainer/MattersContainer";
import NotificationsContainer from "./pages/NotificationsContainer/NotificationsContainer";
import PasswordReset from "./pages/PasswordReset/PasswordReset.lazy";
import WalkContainer from "./pages/WalkContainer/WalkContainer.lazy";

/**
 * App root
 * @returns JSX.Element
 */
function App(): JSX.Element | null {
  const query = useQuery();
  const dispatch = useAppDispatch();
  const { showBoundary } = useErrorBoundary();
  const isConfigInitialised = useAppSelector(selectIsConfigInitialised);
  const isLoggedIn = useAppSelector(selectIsLoggedIn);
  const resumeWalk = useAppSelector(selectResumeWalk);
  const walkIsActive = useAppSelector(selectWalkIsActive);
  const [customerCode, setCustomerCode] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const { pathname, search } = location;

  // This resolves an issue where the user reloads the page and the walk is not active ie. been completed elsewhere
  // or abandoned but the URL is still /walk. This will redirect the user to the walk return URL or document page.
  useEffect(() => {
    if (location.pathname.includes(cRouteType.Walk) && resumeWalk === false && walkIsActive === false) {
      navigate(localStorage.getItem(WALK_RETURN_URL) || `/${cRouteType.Document}`);
    }
  }, [location, resumeWalk]);

  useEffect(() => {
    // Set redirect URL on first mount
    dispatch(updateRedirectUrl(pathname));
  }, []);

  useEffect(() => {
    dispatch(getUserDetails());
  }, [isLoggedIn]);

  // Add any valid router modal routes here
  const routerModalRoutes = [
    `${cRouteType.Document}/:id`,
    `${cRouteType.Notifications}/:id`,
    `${cRouteType.Matters}/:matterId/:id`,
  ];

  /**
   * Returns true if the router modal route url matches any redirect routes
   * @param redirectUrl The redirect url
   * @returns boolean
   */
  function isRouterModalRoute(redirectUrl: string) {
    return routerModalRoutes.some((redirectRoute) =>
      matchPath({ path: redirectRoute, caseSensitive: false }, redirectUrl),
    );
  }

  // Set location.state.background so DocumentInformationModal can open
  useEffect(() => {
    if (isRouterModalRoute(location.pathname)) {
      navigate(`${pathname}${search}`, { state: { background: location } });
    }
  }, [pathname]);

  /**
   * Returns true if the current route is the interview log
   * @returns boolean
   */
  function isInterviewLogRoute() {
    return location.pathname.includes(cRouteType.InterviewLog);
  }

  /**
   * Pulls the ccof from the query param or localstorage
   */
  function handleCustomerCode() {
    // Get the query param customer code
    const param = query.get("ccof");
    // Get the local customer code
    const local = localStorage.getItem(CUSTOMER_CODE);

    // If there is a param or local customer code
    if (param || local) {
      // If there is a param and local is not the same value
      if (!!param && local !== param) {
        // Log the user out since they are attempting to access another customer
        dispatch(getLogout());
      }

      // If there is a param
      if (param) {
        // Set the customer code internal state and localStorage
        setCustomerCode(param);
        localStorage.setItem(CUSTOMER_CODE, param);
      } else {
        // If no param, set the localStorage value to the internal state
        // to run config and user API calls
        setCustomerCode(local as string);
      }
      // If no customer code, throw to error boundary
    } else {
      showBoundary("24000: No customer code");
    }
  }

  /**
   * Gets the config from the server
   */
  async function handleConfig() {
    try {
      await dispatch(postConfig(customerCode || "")).unwrap();
    } catch (error) {
      showBoundary(error);
    }
  }

  useEffect(() => {
    handleCustomerCode();
  }, []);

  useEffect(() => {
    (async () => {
      if (customerCode && isConfigInitialised === false) {
        await handleConfig();
      }
    })();
  }, [customerCode, isConfigInitialised]);

  if (!isConfigInitialised) {
    return <SpinnerBackdrop loading />;
  }

  return (
    <>
      <Routes>
        <Route path={cRouteType.Login} element={<LoginContainer />} />
        <Route path={cRouteType.PasswordReset} element={<PasswordReset />} />
        <Route
          path={cRouteType.Walk}
          element={
            <AuthProvider>
              <WalkContainer />
            </AuthProvider>
          }
        />
        <Route path={cRouteType.Matters}>
          <Route
            index
            element={
              <AuthProvider>
                <NavBarContainer />
                <MattersContainer />
              </AuthProvider>
            }
          />
          <Route
            path=":matterId"
            element={
              <AuthProvider>
                <NavBarContainer />
                <MatterContainer />
              </AuthProvider>
            }
          >
            <Route path=":id" element={<DocumentInformationModalContainer />} />
          </Route>
        </Route>
        <Route
          path={cRouteType.Notifications}
          element={
            <AuthProvider>
              <NavBarContainer />
              <NotificationsContainer />
            </AuthProvider>
          }
        >
          <Route index path=":id" element={<DocumentInformationModalContainer />} />
        </Route>
        <Route
          path={`${cRouteType.Notifications}/${cRouteType.Matters}/:matterId`}
          element={
            <AuthProvider>
              <NavBarContainer />
              <MatterContainer />
            </AuthProvider>
          }
        />
        <Route
          path={cRouteType.Document}
          element={
            <AuthProvider>
              <NavBarContainer />
              <DocumentsContainer />
            </AuthProvider>
          }
        >
          <Route path=":id" element={<DocumentInformationModalContainer />} />
        </Route>
        <Route
          path={`${cRouteType.Admin}/*`}
          element={
            <AuthProvider>
              <AdminProvider>
                <NavBarContainer />
                <AdminContainer />
              </AdminProvider>
            </AuthProvider>
          }
        />
        <Route
          path={`${cRouteType.InterviewLog}/:walkID/:documentTypeID/:matterId?`}
          element={
            <AuthProvider>
              <InterviewLogContainer />
            </AuthProvider>
          }
        />
        <Route
          path={cRouteType.Analytics}
          element={
            <AuthProvider>
              <NavBarContainer />
              <AnalyticsContainer />
            </AuthProvider>
          }
        >
          <Route path={`${cAnalyticsRouteType.PackagedReports}/:code`} element={<PackagedReportContainer />} />
          <Route
            path={`${cAnalyticsRouteType.DocumentAnalytics}/:documentTypeID`}
            element={<DocumentAnalyticsContainer />}
          />
          <Route
            path={`${cAnalyticsRouteType.DocumentAnalytics}/:documentTypeID/:configID`}
            element={<CustomExportContainer />}
          />
          <Route path={`${cAnalyticsRouteType.MatterAnalytics}/:matterTypeID`} element={<MatterAnalyticsContainer />} />
          <Route
            path={`${cAnalyticsRouteType.MatterAnalytics}/:matterTypeID/:type/:configID`}
            element={<MatterCustomExportContainer />}
          />
          <Route path={`${cAnalyticsRouteType.Graphs}/:code`} element={<GraphReportContainer />} />
        </Route>
      </Routes>
      <SpinnerBackdrop />
      {/* Support button and form */}
      {!isInterviewLogRoute() && <SupportContainer />}
      <ToastContainer
        position="top-center"
        theme="dark"
        icon={<Icon icon={EIcon.Success} />}
        hideProgressBar
        closeOnClick
        autoClose={3000}
        transition={Slide}
      />
    </>
  );
}

export default App;
