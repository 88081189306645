import { matchPath, useLocation } from "react-router-dom";
import { cRouteType, WALK_AMEND_TYPE } from "../../app/constants";
import { useAppDispatch, useAppSelector } from "../../app/hooks";
import { updateCollabIsActive } from "../../modules/collaborationSlice";
import { postDocumentsByPage, resetWalkInProgress } from "../../modules/documentsSlice";
import { postMatterDocuments } from "../../modules/matterDocumentsSlice";
import {
  EWalkAction,
  EWalkStage,
  EWalkType,
  postWalkAction,
  selectWalkData,
  TWalkData,
  updateWalkIsActive,
  updateWalkResume,
} from "../../modules/walkSlice";
import ResumeWalkModal from "./ResumeWalkModal/ResumeWalkModal";

interface IResumeWalkModalContainer {
  stage?: EWalkStage;
  documentType?: string;
}

/**
 * Container for the resume walk modal
 * @param stage        The walk stage
 * @param documentType The walk document type
 * @returns JSX.Element
 */
function ResumeWalkModalContainer({ stage, documentType }: IResumeWalkModalContainer): JSX.Element {
  const dispatch = useAppDispatch();
  const walkData = useAppSelector(selectWalkData) as TWalkData; // Fetch the walk data
  const location = useLocation();
  const { pathname } = location;

  const matterUrlMatch = matchPath<"id", string>(
    {
      path: `${cRouteType.Matters}/:id`,
      caseSensitive: false,
    },
    pathname,
  );

  /**
   * Reset walk resume and update is active state for walk
   */
  function continueWalk(): void {
    dispatch(updateWalkResume(false));
    const walkAmendType = localStorage.getItem(WALK_AMEND_TYPE);

    // If the walk is a collaboration and the stage is one where the modal is open, open the collaboration modal,
    // otherwise redirect to the walk
    if (walkAmendType === EWalkType.Collaboration && (stage === EWalkStage.Action || stage === EWalkStage.Collab1)) {
      dispatch(updateCollabIsActive(true));
    } else {
      dispatch(updateWalkIsActive(true));
    }
  }

  /**
   * Discard the in-progress walk
   */
  async function discardWalk(): Promise<void> {
    await dispatch(postWalkAction({ type: EWalkAction.Discard })).unwrap();
    if (walkData.urn) {
      dispatch(resetWalkInProgress(walkData.urn));
    }

    const walkAmendType = localStorage.getItem(WALK_AMEND_TYPE);

    // If the walk is a collaboration, the document data needs to be refreshed to remove the collab in progress state
    if (walkAmendType === EWalkType.Collaboration && (stage === EWalkStage.Action || stage === EWalkStage.Collab1)) {
      await dispatch(postDocumentsByPage(1)).unwrap();
    }

    // If we're discarding a matter interview, refresh list of matter documents
    if (matterUrlMatch) {
      const id = matterUrlMatch.params.id;
      await dispatch(postMatterDocuments(Number(id))).unwrap();
    }
  }

  return (
    <ResumeWalkModal continueWalk={continueWalk} discardWalk={discardWalk} stage={stage} documentType={documentType} />
  );
}

export default ResumeWalkModalContainer;
