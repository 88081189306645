import { createAsyncThunk, createSlice, current, PayloadAction } from "@reduxjs/toolkit";
import { findIndex, has } from "lodash";
import { toast } from "react-toastify";
import api from "../app/api";
import {
  documentAmendUrl,
  documentCloneUrl,
  draftUrl,
  fileUploadUrl,
  matterCloneUrl,
  mattersCreateUrl,
  matterSpawnUrl,
  walkActionUrl,
  walkHelpUrl,
} from "../app/apiUrls";
import { cAccessType, cRouteType, cStatusType, WALK_AMEND_TYPE, WALK_RETURN_URL } from "../app/constants";
import { RootState } from "../app/store";
import { IXDirection } from "../app/types";
import { IUpdateWalkFieldValue } from "../pages/WalkContainer/Walk/WalkInterview/WalkInterview";
import { errorToast } from "../toast/toast";
import { downloadBase64File } from "../utils/download/download";
import { isOutdatedContentError } from "../utils/errors/errors";
import { postMatterGetId } from "./mattersSlice";

/**
 * Walk end type enum
 */
export enum EWalkEndType {
  Discarded = "discarded",
  Paused = "paused",
  Done = "done",
}

/**
 * Action type object
 */
export interface IWalkActionType {
  state: cAccessType;
}

/**
 * Walk action types
 */
export enum EWalkAction {
  Forward = "forward",
  Pause = "pause",
  Back = "back",
  Discard = "discard",
  Confirm = "confirm",
  LaunchToStep = "launchToStep",
  Resume = "resume",
  ResumePaused = "resumePaused",
  Upload = "upload1",
  Close = "close",
  Download = "download",
  Action = "action",
  LaunchPad = "launchPad",
  Save = "save",
}

/**
 * Interface for walk button actions
 */
export type TWalkAction = { [key in EWalkAction]?: IWalkActionType };

/**
 * Interface for walk heading object
 */
interface IWalkHeading {
  major?: string | null;
  minor?: string | null;
}

/**
 * Interface for walk string type field
 */
interface IWalkFieldTypeString {
  columns: number;
  errorText: string;
  justify: Exclude<IXDirection, IXDirection.Center>;
  maxChars: number;
  restriction: string | null;
  rows: number;
  sampleValue?: any;
}

/**
 * Interface for walk list type field
 */
interface IWalkFieldTypeList {
  justify: IXDirection;
  options: string[];
}

/**
 * Interface for single walk field radio type option
 */
interface IWalkFieldTypeRadioOption {
  id: number;
  text: string;
  hint: string;
  help?: IWalkFieldHelp;
  helpContent?: string;
}

/**
 * Interface for walk field radio type options
 */
interface IWalkFieldTypeRadio {
  options: IWalkFieldTypeRadioOption[];
}

/**
 * Interface for walk field email type
 */
interface IWalkFieldTypeEmail {
  errorText: string;
}

/**
 * Interface for walk field date type
 */
interface IWalkFieldTypeDate {
  errorText: string;
}

/**
 * Interface for walk field types
 */
interface IWalkFieldType {
  string?: IWalkFieldTypeString;
  list?: IWalkFieldTypeList;
  radio?: IWalkFieldTypeRadio;
  email?: IWalkFieldTypeEmail;
  date?: IWalkFieldTypeDate;
}

/**
 * Walk field help interface
 */
export interface IWalkFieldHelp {
  important: boolean;
}

/**
 * Interface for walk fields
 */
export interface IWalkField {
  id: number;
  name: string;
  required: boolean;
  instruction: string;
  hint: string;
  type: IWalkFieldType;
  value: string | number | null;
  help?: IWalkFieldHelp;
  helpContent?: string;
}

/**
 * Interface for single question walk field option
 */
export interface IWalkQuestionOption {
  id: number;
  text: string;
  hint: string;
  help?: IWalkFieldHelp;
  helpContent?: string;
}

/**
 * Interface for single walk question
 */
export interface IWalkQuestion {
  id: number;
  text: string;
  hint: string;
  displayAsList: boolean;
  options: IWalkQuestionOption[];
  selectedOptionID: number;
  help: IWalkFieldHelp;
  helpContent?: string;
  step: number;
}

/**
 * Enum listing possible walk stage values
 */
export enum EWalkStage {
  Introduction = "introduction",
  Interview = "interview",
  Discard = "discard",
  Confirm = "confirm",
  ResumePaused = "resumePaused",
  Upload = "upload1",
  Events = "events",
  Download = "download",
  Action = "action",
  LaunchPad = "launchPad",
  Collab1 = "collab1",
  Collab2 = "collab2",
  Error = "error",
}

/**
 * Enum listing possible walk types
 */
export enum EWalkType {
  Draft = "draft",
  File = "file",
  Data = "data",
  Collaboration = "collab",
}

/**
 * Generic walk data
 */
interface IWalkDataGeneric {
  heading?: IWalkHeading;
  action: TWalkAction;
  urn?: number;
  matterRef?: string;
  documentType?: string;
  stages?: {
    hasInterview: boolean;
    hasIntroduction: boolean;
  };
  requireDescription?: boolean;
  walkTypeClass?: EWalkType;
}

/**
 * Interface for user input possibilities (array or single question)
 */
interface IUserInput {
  inputs?: IWalkField[];
  question?: IWalkQuestion;
}

/**
 * Interview confirm data
 */
export interface IInterviewData {
  input?: IWalkField;
  question?: IWalkQuestion;
}

/**
 * Enum listing event types
 */
export enum EEventType {
  System = "system",
  Custom = "custom",
}

/**
 * Interface for a walk event
 */
export interface IWalkEvent {
  id: number;
  isRetrospective: boolean;
  date: string;
  description: string;
  notified: boolean;
  recipients: string[];
  type: EEventType;
  walkUserName: string;
  notifyDate: string;
}

/**
 * Interface to determine what files are available for download
 */
export interface IWalkDownloadFiles {
  word: boolean;
  pdf: boolean;
  powerpoint: boolean;
  excel: boolean;
}

/**
 * Custom walk data based on stage enum
 */
type TWalkDataStage =
  | {
      stage: EWalkStage.Introduction;
      introduction: string;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: never;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Interview;
      introduction?: never;
      userInput?: IUserInput;
      question?: IWalkQuestion;
      interviewData?: never;
      minutesRemaining: number;
      percentComplete: number;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: never;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal: number;
      isFinalised: boolean;
      processID?: never;
    }
  | {
      stage: EWalkStage.Discard;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: string;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: never;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Confirm;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: IInterviewData[];
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: boolean;
      requireVersionNotes?: boolean;
      step?: never;
      documentDescription?: never;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.ResumePaused;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData: IInterviewData[];
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step: number;
      documentDescription?: string;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced: boolean;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Upload;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: boolean;
      step?: never;
      documentDescription?: never;
      previousSignDate?: string;
      requireSignature?: boolean;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: number;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Events;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: never;
      previousSignDate?: never;
      requireSignature?: never;
      events?: IWalkEvent[];
      documentID: number;
      files?: never;
      uploadProgress?: never;
      isFirst: boolean;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Download;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: never;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: IWalkDownloadFiles;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Action;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: string;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.LaunchPad;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData: IInterviewData[];
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: boolean;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: string;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced: boolean;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Error;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: string;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID?: never;
    }
  | {
      stage: EWalkStage.Collab1;
      introduction?: never;
      userInput?: never;
      question?: never;
      interviewData?: never;
      minutesRemaining?: never;
      percentComplete?: never;
      line1?: never;
      autoDescription?: never;
      requireVersionNotes?: never;
      step?: never;
      documentDescription?: string;
      previousSignDate?: never;
      requireSignature?: never;
      events?: never;
      documentID?: never;
      files?: never;
      uploadProgress?: never;
      isFirst?: never;
      interviewEnhanced?: never;
      pauseTotal?: never;
      isFinalised?: never;
      processID: number;
    };

/**
 * Combined data to provide to walk
 */
export type TWalkData = IWalkDataGeneric & TWalkDataStage;

/**
 * Interface for walk state
 */
interface IWalkState {
  data?: TWalkData;
  isActive: boolean;
  resume?: boolean;
  status: cStatusType;
  error?: string;
  handledError: boolean;
  isLoaded: boolean;
}

/**
 * Interface for PostWalkAction
 */
interface IPostWalkAction {
  formData?: any;
  type?: EWalkAction;
  step?: number;
  wipID?: number;
  fileType?: EFileType;
  compositeState?: number;
}

/**
 * Interface for PostMatterSpawn
 */
export interface IPostMatterSpawn {
  custWalkTypeID: number;
  matterID: number;
  spawnID: number;
  compositeState: number;
}

/**
 * Initial state of reducer
 */
const initialState: IWalkState = {
  status: cStatusType.Idle,
  isActive: false,
  handledError: false,
  isLoaded: false,
};

/**
 * Convert walk data by stage
 * @param response The walk API response
 * @returns Object
 */
function getStagedWalkData(response: any): any {
  let res;

  // Convert incoming walk data to state type
  switch (true) {
    case response.data.walk.hasOwnProperty(EWalkStage.Introduction):
      res = convertWalkData(response, EWalkStage.Introduction);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Interview):
      res = convertWalkData(response, EWalkStage.Interview);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Confirm):
      res = convertWalkData(response, EWalkStage.Confirm);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.ResumePaused):
      res = convertWalkData(response, EWalkStage.ResumePaused);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Upload):
      res = convertWalkData(response, EWalkStage.Upload);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Events):
      res = convertWalkData(response, EWalkStage.Events);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Download):
      if (response.data.walk.download.hasOwnProperty("fileData")) {
        downloadBase64File(response.data.walk.download);
      } else {
        res = convertWalkData(response, EWalkStage.Download);
      }
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.LaunchPad):
      res = convertWalkData(response, EWalkStage.LaunchPad);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Action):
      res = convertWalkData(response, EWalkStage.Action);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Collab1):
      res = convertWalkData(response, EWalkStage.Collab1);
      break;

    case response.data.walk.hasOwnProperty(EWalkStage.Collab2):
      res = convertWalkData(response, EWalkStage.Collab2);
      break;

    default:
      res = response.data.walk;
      break;
  }

  return res;
}

/**
 * Convert walk data into useable format
 * @param data  Data from API
 * @param stage The walk stage
 * @returns any
 */
function convertWalkData(response: any, stage: EWalkStage): any {
  return { ...response.data.walk[stage], stage };
}

/**
 * Post draft walk creator
 */
export const postDraft = createAsyncThunk("walk/postDraft", async (walkId: number, { dispatch, rejectWithValue }) => {
  try {
    const endpoint = draftUrl;
    const response: any = await api({ endpoint, dispatch, body: { custWalkTypeID: walkId } });

    let res;

    // Convert incoming walk data to state type
    if (response.data.walk.hasOwnProperty(EWalkStage.Introduction)) {
      res = convertWalkData(response, EWalkStage.Introduction);
    } else {
      res = convertWalkData(response, EWalkStage.Interview);
    }

    return res;
  } catch (err: any) {
    throw rejectWithValue(err.message);
  }
});

/**
 * Post draft walk creator
 */
export const postFileUpload = createAsyncThunk(
  "walk/postFileUpload",
  async (walkId: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = fileUploadUrl;
      const response: any = await api({ endpoint, dispatch, body: { custWalkTypeID: walkId } });

      let res;

      // Convert incoming walk data to state type
      if (response.data.walk.hasOwnProperty(EWalkStage.Introduction)) {
        res = convertWalkData(response, EWalkStage.Introduction);
      } else if (response.data.walk.hasOwnProperty(EWalkStage.Interview)) {
        res = convertWalkData(response, EWalkStage.Interview);
      } else {
        res = convertWalkData(response, EWalkStage.Upload);
      }

      return res;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Post create a new matter walk
 */
export const postCreateMatter = createAsyncThunk(
  "walk/postCreateMatter",
  async (matterTypeID: number, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = mattersCreateUrl;
      const response: any = await api({ endpoint, dispatch, body: { matterTypeID } });

      let res;

      // Convert incoming walk data to state type
      if (response.data.walk.hasOwnProperty(EWalkStage.Introduction)) {
        res = convertWalkData(response, EWalkStage.Introduction);
      } else if (response.data.walk.hasOwnProperty(EWalkStage.Interview)) {
        res = convertWalkData(response, EWalkStage.Interview);
      } else {
        res = convertWalkData(response, EWalkStage.Upload);
      }

      // Get the matter ID from the ref and store as the walk return ID so that users
      // are redirected to the finished matter on walk completion
      const id = await dispatch(postMatterGetId({ ref: res.matterRef }));
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${String(id.payload)}`);

      return res;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Start a spawn walk from a matter
 */
export const postMatterSpawn = createAsyncThunk(
  "walk/postMatterSpawn",
  async ({ custWalkTypeID, matterID, spawnID, compositeState }: IPostMatterSpawn, { dispatch, rejectWithValue }) => {
    try {
      const response = await api({
        endpoint: matterSpawnUrl,
        dispatch,
        body: { custWalkTypeID, matterID, spawnID, compositeState },
      });
      const res = getStagedWalkData(response);

      return res;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Start a document amend walk. This is used for drafting a new version, uploading a new or signed version,
 * and updating interview data.
 */
export const postDocumentAmend = createAsyncThunk(
  "walk/postDocumentAmend",
  async (
    { id, action, compositeState }: { id: number; action: EWalkType; compositeState: number },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const endpoint = documentAmendUrl;

      const response = await api({ endpoint, dispatch, body: { id, compositeState } });
      const res = convertWalkData(response, EWalkStage.Action);

      localStorage.setItem(WALK_AMEND_TYPE, action); // Store the walk type action so that we display the correct action page on resume
      return { data: res, action };
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Start a document clone walk. This is used for creating a copy of an existing document.
 */
export const postDocumentClone = createAsyncThunk(
  "walk/postDocumentClone",
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = documentCloneUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      let res;

      if (response.data.walk.hasOwnProperty("upload1")) {
        res = convertWalkData(response, EWalkStage.Upload);
      } else {
        res = convertWalkData(response, EWalkStage.LaunchPad);
      }

      return res;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Start a Matter clone walk. This is used for creating a copy of an existing Matter.
 */
export const postMatterClone = createAsyncThunk(
  "walk/postMatterClone",
  async ({ id }: { id: number }, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = matterCloneUrl;
      const response = await api({ endpoint, dispatch, body: { id } });
      let res;

      if (response.data.walk.hasOwnProperty("upload1")) {
        res = convertWalkData(response, EWalkStage.Upload);
      } else {
        res = convertWalkData(response, EWalkStage.LaunchPad);
      }

      // Fetch the new Matter's ID based on the returned response
      const newMatterId = await dispatch(postMatterGetId({ ref: res.matterRef })).unwrap();

      // Store the new Matter's URL in localStorage for redirection
      localStorage.setItem(WALK_RETURN_URL, `/matters/${newMatterId}`);

      return res;
    } catch (err: any) {
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Post a walk action
 */
export const postWalkAction = createAsyncThunk(
  "walk/postWalkAction",
  async (
    { formData, type, step, wipID, fileType, compositeState }: IPostWalkAction,
    { getState, dispatch, rejectWithValue },
  ) => {
    try {
      let body;
      const endpoint = walkActionUrl;
      const { walk } = getState() as RootState;

      // If resume
      if (type === EWalkAction.Resume) {
        body = { type };
        // If resume paused
      } else if (type === EWalkAction.ResumePaused) {
        body = {
          type: {
            stage: {
              interview: {
                action: {
                  [EWalkAction.ResumePaused]: {
                    resume: {
                      wipID,
                      compositeState,
                    },
                  },
                },
              },
            },
          },
        };
      }
      // If launch to step
      else if (type === EWalkAction.LaunchToStep) {
        // If launchPad stage
        if (formData && walk.data?.stage === EWalkStage.LaunchPad) {
          body = {
            type: {
              stage: {
                [EWalkStage.LaunchPad]: {
                  action: {
                    ...formData,
                  },
                },
              },
            },
          };
        } else if (walk.data?.interviewEnhanced) {
          body = {
            type: {
              stage: {
                interview: {
                  action: {
                    [EWalkAction.ResumePaused]: {
                      launch: {
                        ...formData,
                      },
                    },
                  },
                },
              },
            },
          };
          // If resuming a paused walk
        } else if (formData) {
          body = {
            type: {
              stage: {
                interview: {
                  action: {
                    [EWalkAction.ResumePaused]: {
                      ...formData,
                    },
                  },
                },
              },
            },
          };
          // Launch to step from confirm stage
        } else if (walk.data?.stage === EWalkStage.Confirm) {
          body = {
            type: {
              stage: {
                [walk.data?.stage as string]: {
                  action: { step },
                },
              },
            },
          };
        } else {
          body = {
            type: {
              stage: {
                interview: {
                  action: {
                    [EWalkAction.ResumePaused]: {
                      launch: { step, inputList: [] },
                    },
                  },
                },
              },
            },
          };
        }
      } else if (type === EWalkAction.Confirm) {
        if (formData) {
          body = {
            type: {
              stage: {
                [walk.data?.stage as string]: {
                  action: {
                    ...formData,
                  },
                },
              },
            },
          };
        } else {
          body = {
            type: {
              stage: {
                [walk.data?.stage as string]: {
                  action: EWalkAction.Save,
                },
              },
            },
          };
        }
      }
      // If upload
      else if (type === EWalkAction.Upload) {
        body = {
          type: {
            stage: {
              [EWalkAction.Upload]: {
                action: {
                  confirm: {
                    ...formData,
                  },
                },
              },
            },
          },
        };
      }
      // If pause
      else if (type === EWalkAction.Pause) {
        if (walk.handledError) {
          // If there is no document description, remove it from the send
          if (!formData?.documentDescription) {
            delete formData.documentDescription;
          }

          body = {
            type: {
              error: {
                pause: { ...formData },
              },
            },
          };
        } else {
          if (!formData) {
            body = {
              type: {
                stage: {
                  [walk.data?.stage as string]: {
                    action: "pause",
                  },
                },
              },
            };
          } else {
            // If there is no document description, remove it from the send
            if (!formData?.documentDescription) {
              delete formData.documentDescription;
            }

            body = {
              type: {
                stage: {
                  [walk.data?.stage as string]: {
                    action: {
                      pause: { ...formData },
                    },
                  },
                },
              },
            };
          }
        }
      }
      // If we want to discard the walk
      else if (type === EWalkAction.Discard) {
        if (formData) {
          body = {
            type: {
              stage: {
                [walk.data?.stage as string]: {
                  action: {
                    discard: {
                      note: formData.note,
                    },
                  },
                },
              },
            },
          };
        } else {
          body = {
            type: {
              stage: {
                [walk.data?.stage as string]: {
                  action: "discard",
                },
              },
            },
          };
        }
      }
      // If we want to close the walk
      else if (type === EWalkAction.Close) {
        body = {
          type: {
            stage: {
              [walk.data?.stage as string]: {
                action: EWalkAction.Close,
              },
            },
          },
        };
      } else if (type === EWalkAction.Download) {
        body = {
          type: {
            stage: {
              download: {
                action: {
                  download: {
                    type: fileType,
                  },
                },
              },
            },
          },
        };
      } else if (type === EWalkAction.Action) {
        body = {
          type: {
            stage: {
              action: {
                action: {
                  forward: {
                    versionNotes: formData.versionNotes,
                    action: formData.action,
                  },
                },
              },
            },
          },
        };
      } else {
        if (formData) {
          // Build expected API body
          body = {
            type: {
              stage: {
                [walk.data?.stage as string]: {
                  action: {
                    [type as string]: { ...formData },
                  },
                },
              },
            },
          };
          // If no form data - send the action as string
        } else {
          body = { type: { stage: { [walk.data?.stage as string]: { action: type } } } }; // Init with action
        }
      }

      let response;

      // If we are on the upload stage, send progress action with the request
      if (type === EWalkAction.Upload) {
        response = await api({
          endpoint,
          dispatch,
          body,
          progressAction: updateWalkUploadProgress,
        });
      } else {
        response = await api({
          endpoint,
          dispatch,
          body,
        });
      }

      if (response.data.walk.hasOwnProperty("error")) {
        return response.data.walk;
      }

      const res = getStagedWalkData(response);

      // If type is resume add resume: true so that the modal opens instead of redirecting to the walk
      if (type === EWalkAction.Resume && !formData) return { ...res, resume: true };

      // Render toast when closing a data amend walk
      if (type === EWalkAction.Close && walk.data?.walkTypeClass === EWalkType.Data) {
        toast("Document data updated");
      }
      return res;
    } catch (err: any) {
      const errorCode = parseInt(err.message);

      // Reset the walk upload progress when we have an error so it's removed from the screen
      if (type === EWalkAction.Upload) {
        dispatch(updateWalkUploadProgress(0));
      }
      if (!isOutdatedContentError(errorCode)) {
        errorToast(err.message);
      }
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Types of walk fields
 */
export enum EWalkFieldType {
  InputHelp = "inputHelp",
  QuestionHelp = "questionHelp",
  OptionHelp = "optionHelp",
}

/**
 * Interface for fetchHelp function params
 */
interface IFetchHelpParams {
  id: number;
  type: EWalkFieldType;
}

/**
 * The potential file types to download
 */
export enum EFileType {
  Word = "word",
  Pdf = "pdf",
  Powerpoint = "powerpoint",
  Excel = "excel",
}

/**
 * Fetch help contents when help icon button is clicked (to render in popover)
 */
export const postWalkHelp = createAsyncThunk(
  "walk/postWalkHelp",
  async ({ id, type }: IFetchHelpParams, { dispatch, rejectWithValue }) => {
    try {
      const endpoint = walkHelpUrl;
      const body = { type, id };
      const response = await api({ endpoint, dispatch, body }); // Run the API call
      response.id = id; // Include the index of the field with the response to the reducer so that we can update that member
      return response;
    } catch (err: any) {
      errorToast(err.message);
      throw rejectWithValue(err.message);
    }
  },
);

/**
 * Get input help function interface
 */
interface IGetInputHelp {
  inputTypeHelp: string | null;
  inputFieldHelp: string | null;
}

/**
 * Concatenate input help contents
 * @param inputTypeHelp  Content from API
 * @param inputFieldHelp Content from API
 * @returns string
 */
function getInputHelp({ inputTypeHelp, inputFieldHelp }: IGetInputHelp): string {
  let helpContent = "";

  if (inputFieldHelp) helpContent = `${inputFieldHelp} `;
  if (inputTypeHelp) helpContent += inputTypeHelp;
  helpContent = helpContent.trim();

  return helpContent;
}

/**
 * Walk reducer of state
 */
export const walkSlice = createSlice({
  name: "walk",
  initialState,
  reducers: {
    // Update isActive
    updateWalkIsActive: (state, action: PayloadAction<boolean>) => {
      state.isActive = action.payload;
    },
    // Update resume flag
    updateWalkResume: (state, action: PayloadAction<boolean>) => {
      state.resume = action.payload;
    },
    // Update walk field value
    updateWalkInterviewFieldValue: (state, action: PayloadAction<IUpdateWalkFieldValue>) => {
      const userInputPath = "data.userInput";
      if (has(current(state), `${userInputPath}.inputs`)) {
        const fields = current(state).data?.userInput?.inputs;
        const targetIndex = findIndex(fields as IInterviewData[], ["name", action.payload.name]);

        if (targetIndex !== -1) {
          state.data!.userInput!.inputs![targetIndex]!.value = action.payload.value as string;
        }
      } else if (has(current(state), `${userInputPath}.question`)) {
        state.data!.userInput!.question!.selectedOptionID = Number(action.payload.value);
      }
    },
    updateWalkUploadProgress: (state, action: PayloadAction<number>) => {
      state.data!.uploadProgress = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      // Change status to loading
      .addCase(postDraft.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set error
      .addCase(postDraft.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Fulfil state data
      .addCase(postDraft.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload;
        state.isActive = true;
        state.isLoaded = true;
      }) // Change status to loading
      .addCase(postFileUpload.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set error
      .addCase(postFileUpload.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Fulfil state data
      .addCase(postFileUpload.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload;
        state.isActive = true;
        state.isLoaded = true;
      })
      // Change status to loading when API call is pending
      .addCase(postWalkAction.pending, (state) => {
        state.status = cStatusType.Loading;
      })
      // Change status to failed if API call fails
      .addCase(postWalkAction.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Change status to idle if API call is successful
      .addCase(postWalkAction.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;

        if (action.payload) {
          if (action.payload.hasOwnProperty("error")) {
            if (
              state.hasOwnProperty("data") &&
              typeof state.data === "object" &&
              state.data.stage !== EWalkStage.Error
            ) {
              state.data.requireDescription = action.payload.error.requireDescription;
            } else {
              state.data = {
                stage: EWalkStage.Error,
                action: {},
                requireDescription: action.payload.error.requireDescription,
              };
            }
            state.isActive = true;
            state.handledError = true;
          } else if ([EWalkEndType.Discarded, EWalkEndType.Paused, EWalkEndType.Done].includes(action.payload)) {
            state.data = undefined;
            state.isActive = false;
            state.resume = false;
            state.handledError = false;
          } else {
            if (action.payload.resume) {
              state.resume = true;
            }
            if (action.payload.stage === EWalkStage.Events) {
              state.isActive = true;
            }
            state.data = action.payload;
            state.handledError = false;
          }
        }
        state.isLoaded = true;
      })
      // Change status to failed if API call fails
      .addCase(postWalkHelp.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Change status to idle if API call is successful
      .addCase(postWalkHelp.fulfilled, (state, action) => {
        if (
          state.data?.stage === EWalkStage.Confirm ||
          state.data?.stage === EWalkStage.LaunchPad ||
          state.data?.stage === EWalkStage.ResumePaused
        ) {
          if (state.data?.interviewData) {
            const index: number = findIndex(state.data?.interviewData, (item): boolean => {
              if (item.input) {
                return item.input.id === action.payload.id;
              } else if (item.question) {
                return item.question.id === action.payload.id;
              }

              return false;
            });

            if (state.data?.interviewData?.[index]?.input) {
              const helpContent = getInputHelp(action.payload.data);

              state.data.interviewData[index].input!.helpContent = helpContent;
            } else {
              state.data.interviewData[index].question!.helpContent = action.payload.data.questionHelp;
            }
          }
        } else {
          if (action.payload.data.hasOwnProperty("inputFieldHelp")) {
            const index = findIndex(state.data?.userInput?.inputs, ["id", action.payload.id]);

            if (state.data?.userInput?.inputs) {
              const helpContent = getInputHelp(action.payload.data);

              state.data.userInput.inputs[index].helpContent = helpContent;
            }
          } else if (action.payload.data.hasOwnProperty("questionHelp")) {
            if (state.data?.userInput?.question) {
              state.data.userInput.question.helpContent = action.payload.data.questionHelp;
            }
          } else {
            const index = findIndex(state.data?.userInput?.question?.options, ["id", action.payload.id]);

            if (state.data?.userInput?.question) {
              state.data.userInput.question.options[index].helpContent = action.payload.data.optionHelp;
            }
          }
        }
        state.isLoaded = true;
      }) // Change status to loading
      .addCase(postCreateMatter.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Set error
      .addCase(postCreateMatter.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Fulfil state data
      .addCase(postCreateMatter.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload;
        state.isActive = true;
        state.isLoaded = true;
      })
      // Document amend loading
      .addCase(postDocumentAmend.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Document amend rejected - display error
      .addCase(postDocumentAmend.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Document amend fulfilled - set walk data
      .addCase(postDocumentAmend.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload.data;

        if (action.payload.action !== EWalkType.Collaboration) {
          state.isActive = true;
        }
        state.isLoaded = true;
      })
      // Document clone loading
      .addCase(postDocumentClone.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Document clone rejected - display error
      .addCase(postDocumentClone.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Document clone fulfilled - set walk data
      .addCase(postDocumentClone.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload;
        state.isActive = true;
        state.isLoaded = true;
      })
      // Matter spawn loading
      .addCase(postMatterSpawn.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Matter spawn rejected
      .addCase(postMatterSpawn.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Matter spawn fulfilled
      .addCase(postMatterSpawn.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload;
        state.isActive = true;
        state.isLoaded = true;
      })
      // Matter clone loading
      .addCase(postMatterClone.pending, (state) => {
        state.status = cStatusType.Loading;
        state.error = undefined;
      })
      // Document clone rejected - display error
      .addCase(postMatterClone.rejected, (state, action) => {
        state.status = cStatusType.Failed;
        state.error = action.payload as string;
      })
      // Document clone fulfilled - set walk data
      .addCase(postMatterClone.fulfilled, (state, action) => {
        state.status = cStatusType.Idle;
        state.data = action.payload;
        state.isActive = true;
        state.isLoaded = true;
      });
  },
});

export const { updateWalkIsActive, updateWalkResume, updateWalkInterviewFieldValue, updateWalkUploadProgress } =
  walkSlice.actions;

export const selectWalkData = (state: RootState) => state.walk.data; // Select walk data
export const selectWalkError = (state: RootState) => state.walk.error; // Select walk error
export const selectWalkIsActive = (state: RootState) => state.walk.isActive; // Select walk isActive state
export const selectWalkResume = (state: RootState) => state.walk.resume; // Select walk resume state
export const selectWalkStatus = (state: RootState) => state.walk.status; // Select walk status
export const selectHasInterview = (state: RootState) => state.walk.data?.stages?.hasInterview; // Select hasInterview
export const selectWalkHandledError = (state: RootState) => state.walk.handledError; // Select handled error
export const selectWalkIsLoaded = (state: RootState) => state.walk.isLoaded; // Select walk isLoaded state

export default walkSlice.reducer;
