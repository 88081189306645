import { format, parseISO, startOfYear } from "date-fns";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { cMonoColorType, cRouteType, cSizeType, CUSTOMER_CODE } from "../../../../app/constants";
import { unableToViewMatterErrorCode } from "../../../../app/errorCodes";
import { useQuery } from "../../../../app/hooks";
import Div from "../../../../components/Div/Div";
import HandledErrorModal from "../../../../components/HandledErrorModal/HandledErrorModal";
import Spinner from "../../../../components/Spinner/Spinner";
import TableCard from "../../../../components/TableCard/TableCard";
import TruncatedPopover from "../../../../components/TruncatedPopover/TruncatedPopover";
import Typography from "../../../../components/Typography/Typography";
import { TLinkedMatterRender } from "../../../../modules/mattersSlice";
import styles from "./LinkedMatters.module.scss";

interface ILinkedMatters {
  linkedMatters: TLinkedMatterRender[];
}

/**
 * Linked matters within a matter
 * @param linkedMatters The linked matters
 * @returns JSX.Element
 */
function LinkedMatters({ linkedMatters }: ILinkedMatters): JSX.Element {
  const query = useQuery();
  const navigate = useNavigate();
  const [isHandledErrorModalOpen, setIsHandledErrorModalOpen] = useState(false);
  const [matterRef, setMatterRef] = useState<string | undefined>(undefined);

  // Table columns
  const columns = [
    {
      heading: "Date",
      width: "140px",
      testId: "table-filter-date",
    },
    {
      heading: "REF",
    },
    {
      heading: "Type",
    },
    {
      heading: "Description",
    },
    {
      heading: "Owner",
    },
  ];

  // Table rows
  const rows = linkedMatters.map(({ referenceCode, createdDtm, owner, matterType, description }) => {
    return [
      <>
        <Div className={styles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Date:&nbsp;
            </Typography>
          </Div>
          {format(parseISO(createdDtm), new Date(createdDtm) < startOfYear(new Date()) ? "dd MMM yyyy" : "dd MMM")}
        </Div>
      </>,
      <>
        <Div className={styles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Ref:&nbsp;
            </Typography>
          </Div>
          <TruncatedPopover>{referenceCode}</TruncatedPopover>
        </Div>
      </>,
      <>
        <Div className={styles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Type:&nbsp;
            </Typography>
          </Div>
          {matterType ? <TruncatedPopover>{matterType}</TruncatedPopover> : <Spinner size={cSizeType.Small} />}
        </Div>
      </>,
      <>
        <Div className={styles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Description:&nbsp;
            </Typography>
          </Div>
          <TruncatedPopover>{description}</TruncatedPopover>
        </Div>
      </>,
      <>
        <Div className={styles.group}>
          <Div display={{ base: "block", md: "none" }}>
            <Typography variant="small" color={cMonoColorType.Light}>
              Owner:&nbsp;
            </Typography>
          </Div>
          {owner ? <TruncatedPopover>{owner}</TruncatedPopover> : <Spinner size={cSizeType.Small} />}
        </Div>
      </>,
    ];
  });

  /**
   * Routes to the matter on table row click or shows HandledErrorModal if no privileges to view matter
   * @param tableRowIndex The row index clicked
   */
  function handleTableRowClick(tableRowIndex: number): void {
    const matterID = linkedMatters[tableRowIndex].id;
    const matterRef = linkedMatters[tableRowIndex].referenceCode;
    const canView = linkedMatters[tableRowIndex].canView;
    if (canView === false) {
      setMatterRef(matterRef);
      setIsHandledErrorModalOpen(true);
    } else {
      const customerCode = query.get("ccof") || localStorage.getItem(CUSTOMER_CODE);
      navigate(`/${cRouteType.Matters}/${matterID}?ccof=${customerCode}`);
    }
  }

  /**
   * Closes handled error modal
   */
  function handleHandledErrorModalClose() {
    setIsHandledErrorModalOpen(false);
    setMatterRef(undefined);
  }

  return (
    <>
      <Div p={{ base: 4 }} className={styles.tableWrapper}>
        <TableCard
          columns={columns}
          rows={rows}
          variant="timeline"
          onRowClick={handleTableRowClick}
          testId="linked-matters-table"
          hasClickableRows
        />
      </Div>
      {isHandledErrorModalOpen && (
        <HandledErrorModal
          isOpen
          handleClose={handleHandledErrorModalClose}
          errorCode={unableToViewMatterErrorCode}
          matterRef={matterRef}
        />
      )}
    </>
  );
}

export default LinkedMatters;
