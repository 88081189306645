import { cAccessType, cSizeType, cThemeColorType } from "../../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../../app/types";
import Button, { EButtonVariant } from "../../../../../../components/Button/Button";
import Div from "../../../../../../components/Div/Div";
import Icon, { EIcon } from "../../../../../../components/Icon/Icon";
import Popover from "../../../../../../components/Popover/Popover";
import Column from "../../../../../../components/Row/Column/Column";
import Row from "../../../../../../components/Row/Row";
import Typography from "../../../../../../components/Typography/Typography";
import { ISpawnItem } from "../../../../../../modules/matterTasksSlice";
import { IUser } from "../../../../../../modules/usersSlice";
import { EWalkType, IPostMatterSpawn } from "../../../../../../modules/walkSlice";
import { IMatterTasksColumn } from "../MatterTasksColumn";
import styles from "./MatterTask.module.scss";

type TPartialMatterTasksColumn = Pick<IMatterTasksColumn, "handleDraftDocument" | "setSelectedTask">;
type TPartialMatterTasksColumnWithOptionalSetSelectedTask = {
  handleDraftDocument: TPartialMatterTasksColumn["handleDraftDocument"];
  setSelectedTask?: TPartialMatterTasksColumn["setSelectedTask"];
};

type TMatterTask = ISpawnItem &
  Omit<IPostMatterSpawn, "spawnID"> &
  TPartialMatterTasksColumnWithOptionalSetSelectedTask & { taskAssignee?: IUser };

/**
 * A single matter task item
 * @param id                  The spawn ID
 * @param name                The spawn name
 * @param description         The spawn description
 * @param state               Spawn draftable state
 * @param walkType            Draft or file walk type
 * @param custWalkTypeID      Walk type ID to start interview
 * @param isLocked            Is the spawn locked
 * @param lockedState         Can the spawn be unlocked?
 * @param lockedMessage       Lock message to display (if any)
 * @param handleDraftDocument Start spawn walk
 * @param matterID            The wrapping matter ID
 * @param isLaunchExternally  Is the spawn launched externally? eg. API
 * @param compositeState      The composite state of the spawn
 * @param taskAssignee        The assignee for the task
 * @param setSelectedTask     Set the selected task for reassignment modal
 * @param canReassign         Can the task be reassigned?
 * @returns JSX.Element
 */
function MatterTask({
  id,
  name,
  description,
  state,
  walkType,
  custWalkTypeID,
  isLocked,
  lockedState,
  lockedMessage,
  handleDraftDocument,
  matterID,
  isLaunchExternally,
  compositeState,
  taskAssignee,
  setSelectedTask,
  canReassign,
}: TMatterTask): JSX.Element {
  const tooltipUpload = "Start via Upload";
  const tooltipDraft = "Start via Draft";
  const tooltipDenied = "Insufficient privileges";
  const tooltipInaccessible = "Currently locked for enhancement";
  const tooltipWillLaunch = "Will launch automatically";

  /**
   * Is the access denied?
   * @param access The access state of the upload/draft button
   * @returns boolean
   */
  function isAccessDenied(access: cAccessType) {
    return access === cAccessType.Denied;
  }

  /**
   * Is the access inaccessible?
   * @param access The access state of the upload/draft button
   * @returns boolean
   */
  function isAccessInaccessible(access: cAccessType) {
    return access === cAccessType.Inaccessible;
  }

  /**
   * Is the access disabled?
   * @param access The access state of the upload/draft button
   * @returns boolean
   */
  function isAccessDisabled(access: cAccessType) {
    return access === cAccessType.Disabled;
  }

  /**
   * Is the button disabled?
   * @param access The access state of the upload/draft button
   * @returns boolean
   */
  function isBtnDisabled(access: cAccessType) {
    return isAccessDisabled(access) || isAccessDenied(access) || isAccessInaccessible(access) || isLocked;
  }

  /**
   * Returns the correct tooltip text depending on the btn state
   * @param access      The access state of the upload/draft button
   * @param tooltipText The tooltip text to show if the btn is enabled
   * @returns string
   */
  function getTooltipText(access: cAccessType, tooltipText: string) {
    if (isAccessDisabled(access)) {
      return tooltipInaccessible;
    } else if (isAccessDenied(access)) {
      return tooltipDenied;
    } else if (isAccessInaccessible(access)) {
      return tooltipInaccessible;
    } else {
      return tooltipText;
    }
  }

  /**
   * Renders the correct button
   * @returns JSX.Element
   */
  function renderButton() {
    if (isLaunchExternally) {
      return (
        <Popover
          variant="tooltip"
          popoverPlacement={EPopoverPlacement.Top}
          width="auto"
          popoverContents={tooltipWillLaunch}
          divProps={{
            icon: EIcon.ApiTask,
            iconColor: cThemeColorType.Secondary,
          }}
        />
      );
    }
    if (isLocked) {
      return (
        <Button
          variant={EButtonVariant.Square}
          icon={EIcon.Unlink}
          disabled={lockedState === cAccessType.Disabled}
          color={cThemeColorType.Secondary}
          testId="spawn-locked-btn"
        />
      );
    } else {
      if (walkType === EWalkType.File) {
        return (
          <Popover
            variant="tooltip"
            popoverPlacement={EPopoverPlacement.Top}
            width="auto"
            popoverContents={<Div>{getTooltipText(state, tooltipUpload)}</Div>}
            buttonContents={<Icon icon={EIcon.UploadDocument} />}
            buttonProps={{
              color: cThemeColorType.Secondary,
              disabled: isBtnDisabled(state),
              onClick: () => {
                handleDraftDocument({ custWalkTypeID, matterID, spawnID: id, compositeState });
              },
              testId: "spawn-file-btn",
            }}
          />
        );
      } else {
        return (
          <Popover
            variant="tooltip"
            popoverPlacement={EPopoverPlacement.Top}
            width="auto"
            popoverContents={<Div>{getTooltipText(state, tooltipDraft)}</Div>}
            buttonContents={<Icon icon={EIcon.UpdateDocument} />}
            buttonProps={{
              color: cThemeColorType.Secondary,
              disabled: isBtnDisabled(state),
              onClick: () => {
                handleDraftDocument({ custWalkTypeID, matterID, spawnID: id, compositeState });
              },
              testId: "spawn-draft-btn",
            }}
          />
        );
      }
    }
  }

  return (
    <Div className={styles.matterTask} testId="matter-task">
      <Row justifyContent={{ base: "space-between" }} alignItems={{ base: "flex-start" }}>
        <Column all={9} spacing={{ pr: 4 }}>
          <Typography className={styles.name} weight="medium">
            {name}
          </Typography>
          <Typography className={styles.description}>{description}</Typography>
          {taskAssignee && setSelectedTask && (
            <Button
              variant={EButtonVariant.Link}
              icon={EIcon.User}
              size={cSizeType.Small}
              spacing={{ mt: 5 }}
              className={`${styles.assignee} ${!canReassign ? styles.disabled : ""}`}
              onClick={() => {
                if (canReassign) {
                  setSelectedTask({ id, name, description, assigneeUserID: taskAssignee.id });
                }
              }}
              disabled={!canReassign}
            >{`${taskAssignee?.firstName} ${taskAssignee?.lastName}`}</Button>
          )}
          {lockedMessage && <Typography className={styles.lockedMsg}>{lockedMessage}</Typography>}
        </Column>
        <Column all={3} display={{ base: "flex" }} justifyContent={{ base: "flex-end" }}>
          {renderButton()}
        </Column>
      </Row>
    </Div>
  );
}

export default MatterTask;
