import { find, map, orderBy } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { cRouteType, WALK_RETURN_URL } from "../../app/constants";
import { useAppDispatch, useAppSelector, useCreateOrCloneMatterActions } from "../../app/hooks";
import InformationUpdatedModal from "../../components/InformationUpdatedModal/InformationUpdatedModal";
import { updateCollabIsActive } from "../../modules/collaborationSlice";
import { openInformationUpdatedModal, selectIsInformationUpdatedModalOpen } from "../../modules/documentsSlice";
import {
  deleteMatterDocument,
  postMatterDocuments,
  resetMatterDocuments,
  selectMatterDocuments,
  selectMatterDocumentsError,
  selectMatterDocumentsStatus,
} from "../../modules/matterDocumentsSlice";
import { selectMatterDocumentTypes, selectMatterDocumentTypesError } from "../../modules/matterDocumentTypesSlice";
import {
  postLinkedMatters,
  postMattersById,
  selectLinkedMatters,
  selectMatters,
  selectMattersByIdStatus,
  selectMattersError,
} from "../../modules/mattersSlice";
import {
  postMatterTimeline,
  resetMattersTimeline,
  selectMattersTimelineError,
} from "../../modules/mattersTimelineSlice";
import {
  postMatterTags,
  selectMatterTags,
  selectMatterTagsError,
  selectMatterTagsStatus,
} from "../../modules/matterTagsSlice";
import { postMatterTasks } from "../../modules/matterTasksSlice";
import {
  postMatterTypes,
  selectMatterTypes,
  selectMatterTypesError,
  selectMatterTypesStatus,
} from "../../modules/matterTypesSlice";
import { selectUsers, selectUsersError } from "../../modules/usersSlice";
import {
  EWalkAction,
  EWalkType,
  postDocumentAmend,
  postDocumentClone,
  postWalkAction,
  selectWalkIsActive,
  updateWalkIsActive,
} from "../../modules/walkSlice";
import { errorToast } from "../../toast/toast";
import {
  isDocumentAbandonedError,
  isNotFoundError,
  isOutdatedContentError,
  isUnableToViewMatterError,
} from "../../utils/errors/errors";
import Matter from "./Matter/Matter";

/**
 * Data container for single matter page
 * @returns JSX.Element
 */
function MatterContainer(): JSX.Element {
  const dispatch = useAppDispatch();
  const mattersTimelineError = useAppSelector(selectMattersTimelineError);
  const navigate = useNavigate(); // History object for handling redirect
  const isInformationUpdatedModalOpen = useAppSelector(selectIsInformationUpdatedModalOpen);
  const location = useLocation();
  const { matterId } = useParams<{ matterId: string }>();
  const [intId, setIntId] = useState<number>(); // Use state to prevent additional renders
  const { handleCreateOrCloneMatterClick } = useCreateOrCloneMatterActions();

  // Convert id to integer
  useEffect(() => setIntId(parseInt(matterId as string, 10)), [matterId]);
  const { pathname } = location;

  /**
   * Redirect the user to the previous page
   */
  function redirectToPrevPage() {
    navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf("/"))}`);
  }

  // If the user is unable to view the timeline items, redirect the user
  // back to the previous page (this is common on matter transfer where
  // the user loses privileges and the timeline is refreshed)
  useEffect(() => {
    if (mattersTimelineError) {
      if (isUnableToViewMatterError(parseInt(mattersTimelineError, 10))) {
        redirectToPrevPage();
      }
    }
  }, [mattersTimelineError]);

  useEffect(() => {
    // Clear the matter timeline and matter documents on unmount
    // as they could have changed or been deleted
    return () => {
      dispatch(resetMattersTimeline());
      dispatch(resetMatterDocuments(intId as number));
    };
  }, []);

  /**
   * Get the base matter data
   */
  async function getMatterData() {
    await dispatch(postMattersById({ ids: [intId as number], force: true }))
      .unwrap()
      .catch((error) => {
        const errorCode = parseInt(error, 10);

        // Render more appropriate error messages for privileges and not found errors
        if (isUnableToViewMatterError(errorCode)) {
          errorToast(`${errorCode}: You do not have access to view this matter`);
        } else if (isNotFoundError(errorCode)) {
          errorToast(`${errorCode}: Matter not found.`);
        } else {
          errorToast(error);
        }

        // Redirect the user
        redirectToPrevPage();
      });
  }

  // Get the matter, matter tags, matter documents and linked matters
  useEffect(() => {
    if (intId) {
      getMatterData();
      dispatch(postMatterTags(intId));
      dispatch(postMatterDocuments(intId));
      dispatch(postLinkedMatters(intId));
    }
  }, [intId]);

  const walkIsActive = useAppSelector(selectWalkIsActive); // Get walk state

  const rawMatters = useAppSelector(selectMatters);
  const mattersStatus = useAppSelector(selectMattersByIdStatus);
  const mattersError = useAppSelector(selectMattersError);
  const rawLinkedMatters = useAppSelector(selectLinkedMatters);

  const matterTypes = useAppSelector(selectMatterTypes);
  const matterTypesStatus = useAppSelector(selectMatterTypesStatus);
  const matterTypesError = useAppSelector(selectMatterTypesError);

  const mattersTags = useAppSelector(selectMatterTags);
  const matterTagsStatus = useAppSelector(selectMatterTagsStatus);
  const matterTagsError = useAppSelector(selectMatterTagsError);

  const mattersDocuments = useAppSelector(selectMatterDocuments);
  const matterDocumentsStatus = useAppSelector(selectMatterDocumentsStatus);
  const matterDocumentsError = useAppSelector(selectMatterDocumentsError);

  const matter = rawMatters[parseInt(matterId as string, 10)];
  const matterTypeObj = matterTypes[matter?.matterType.id];
  const displayMilestone = matterTypeObj?.displayMilestones;
  const matterType = matterTypeObj?.name;
  const matterTags = mattersTags[intId as number];
  const matterDocuments = mattersDocuments[intId as number];

  const users = useAppSelector(selectUsers); // Get all users
  const usersError = useAppSelector(selectUsersError); // Get users error
  const documentTypes = useAppSelector(selectMatterDocumentTypes); // Get all the document types
  const documentTypesError = useAppSelector(selectMatterDocumentTypesError); // Get document types error

  // Get the linked matter type
  useEffect(() => {
    if (matter) {
      dispatch(postMatterTypes([matter.matterType.id]));
    }
  }, [matter]);

  useEffect(() => {
    // If walk is active, redirect to the walk view
    if (walkIsActive) {
      navigate(`/${cRouteType.Walk}`);
    }
  }, [walkIsActive]);

  // Map the documents and replace IDs with descriptive data
  const documents = orderBy(
    map(matterDocuments?.documents, (document) => {
      const documentType = documentTypes?.[document.documentType.id]?.name;
      const ownerObj = users?.[document.ownerUser.id];

      return {
        ...document,
        documentType,
        owner: ownerObj?.lastName ? `${ownerObj?.firstName} ${ownerObj?.lastName}` : ownerObj?.firstName,
      };
    }),
    "historySortDate",
    "desc",
  );

  const linkedMatters = orderBy(
    map(rawLinkedMatters, (matter) => {
      const matterType = matterTypes?.[matter.matterType.id]?.name;
      const ownerObj = users?.[matter.ownerUser.id];

      return {
        ...matter,
        matterType,
        owner: ownerObj?.lastName ? `${ownerObj?.firstName} ${ownerObj?.lastName}` : ownerObj?.firstName,
      };
    }),
    ["createdDtm", "id"],
    ["desc", "desc"],
  );

  /**
   * Refresh the matter timeline on adding a new event
   */
  function onAddEventSuccess(): void {
    dispatch(postMatterTimeline(intId as number));
  }

  /**
   * Refresh the matter documents on document updates
   */
  function onDocumentUpdate(): void {
    dispatch(postMatterDocuments(intId as number));
  }

  /**
   * Start a document amend walk
   * @param id             The id of the document
   * @param action         The type of walk (draft | file | data)
   * @param compositeState Matter composite state for change checking
   */
  async function handleDocumentAmend(id: number, action: EWalkType, compositeState: number) {
    try {
      await dispatch(postDocumentAmend({ id, action, compositeState })).unwrap();
      if (pathname.includes(cRouteType.Notifications)) {
        localStorage.setItem(WALK_RETURN_URL, cRouteType.Notifications);
      } else if (pathname.includes(cRouteType.Matters)) {
        localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${intId}`);
      } else {
        localStorage.setItem(WALK_RETURN_URL, `/${cRouteType.Matters}`);
      }

      // If the walk is a collaboration, activate the collaboration state
      if (action === EWalkType.Collaboration) {
        dispatch(updateCollabIsActive(true));
      }
    } catch (error: any) {
      console.error(error);
      const errorCode = parseInt(error);
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      }
      // Handle abandoned document by setting a fallback return URL
      if (isDocumentAbandonedError(errorCode)) {
        localStorage.setItem(WALK_RETURN_URL, `/${cRouteType.Matters}`);
      }
    }
  }

  /**
   * Start a clone document walk
   * @param id The ID of the document to be cloned
   */
  async function handleCloneDocumentClick(id: number) {
    await dispatch(postDocumentClone({ id })).unwrap();
    if (pathname.includes(cRouteType.Notifications)) {
      localStorage.setItem(WALK_RETURN_URL, cRouteType.Notifications);
    } else {
      localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${intId}`);
    }
  }

  /**
   * Resumes a walk
   * @param wipID          The walk in progress id
   * @param id             The walk matter ID
   * @param compositeState Matter composite state for change checking
   * @param isCollab       Is the walk a collaboration
   */
  async function resumeWalk(wipID: number, id: string, compositeState?: number, isCollab: boolean = false) {
    try {
      await dispatch(postWalkAction({ type: EWalkAction.ResumePaused, wipID, compositeState })).unwrap();

      // If the walk is a collaboration, update the collaboration state
      // Otherwise, update the walk state
      if (isCollab) {
        dispatch(updateCollabIsActive(true));
      } else {
        dispatch(updateWalkIsActive(true));
      }
      // Set the return URL based on the current pathname
      if (pathname.includes(cRouteType.Notifications)) {
        localStorage.setItem(WALK_RETURN_URL, cRouteType.Notifications);
      } else if (pathname.includes(cRouteType.Matters)) {
        localStorage.setItem(WALK_RETURN_URL, `${cRouteType.Matters}/${id}`);
      } else {
        localStorage.setItem(WALK_RETURN_URL, `/${cRouteType.Matters}`);
      }
    } catch (error) {
      const errorCode = parseInt(error as string, 10);
      // Delete the matter document if it has been abandonded
      if (isDocumentAbandonedError(errorCode)) {
        const foundDocument = find(matterDocuments?.documents, (document) => document.walkInProgress?.wipID === wipID);
        if (foundDocument) {
          dispatch(deleteMatterDocument({ documentID: foundDocument.id, matterID: intId as number }));
        }
        // Set the return URL to the Matters list when a matter is abandoned
        localStorage.setItem(WALK_RETURN_URL, `/${cRouteType.Matters}`);
      }
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      }
      dispatch(postMatterTasks(Number(id)));
      console.error(error);
    }
  }

  return (
    <>
      <Matter
        urn={matter?.referenceCode}
        title={matter?.description}
        matterStatus={mattersStatus}
        error={mattersError || matterTypesError || matterTagsError}
        matterType={matterType}
        matterTypeStatus={matterTypesStatus}
        matterTags={matterTags}
        matterTagsStatus={matterTagsStatus}
        documents={documents}
        rawDocuments={matterDocuments?.documents}
        matterDocumentsStatus={matterDocumentsStatus}
        matterDocumentsError={matterDocumentsError || usersError || documentTypesError}
        id={matterId as string}
        displayMilestone={displayMilestone}
        milestoneDisplayName={matter?.milestoneDisplayName}
        handleDocumentAmend={handleDocumentAmend}
        handleCloneDocumentClick={handleCloneDocumentClick}
        onAddEventSuccess={onAddEventSuccess}
        onDocumentUpdate={onDocumentUpdate}
        matters={rawMatters}
        linkedMatters={linkedMatters}
        resumeWalk={resumeWalk}
        getMatterData={getMatterData}
        handleCreateOrCloneMatterClick={handleCreateOrCloneMatterClick}
      />
      <InformationUpdatedModal isOpen={isInformationUpdatedModalOpen} />
    </>
  );
}

export default MatterContainer;
