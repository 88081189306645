import { Action, combineReducers, configureStore, Reducer, ThunkAction, UnknownAction } from "@reduxjs/toolkit";
import { createStateSyncMiddleware, initStateWithPrevTab } from "redux-state-sync";
import analyticsReducer from "../modules/analyticsSlice";
import categoriesReducer from "../modules/categoriesSlice";
import collaborationReducer from "../modules/collaborationSlice";
import customerReducer from "../modules/customerSlice";
import documentsReducer from "../modules/documentsSlice";
import documentStagesReducer from "../modules/documentStagesSlice";
import documentStatesReducer from "../modules/documentStatesSlice";
import documentTypesReducer from "../modules/documentTypesSlice";
import errorCodesReducer from "../modules/errorCodesSlice";
import esignReducer from "../modules/esignSlice";
import eventCustomReducer from "../modules/eventCustomSlice";
import interviewLogReducer from "../modules/interviewLogSlice";
import loadingReducer from "../modules/loadingSlice";
import mainMenuReducer from "../modules/mainMenuSlice";
import matterDocumentsReducer from "../modules/matterDocumentsSlice";
import matterDocumentTypesReducer from "../modules/matterDocumentTypesSlice";
import mattersReducer from "../modules/mattersSlice";
import mattersTimelineReducer from "../modules/mattersTimelineSlice";
import matterTagsReducer from "../modules/matterTagsSlice";
import matterTasksReducer from "../modules/matterTasksSlice";
import matterTypesReducer from "../modules/matterTypesSlice";
import navBarReducer from "../modules/navBarSlice";
import notificationsReducer from "../modules/notificationsSlice";
import notifyGroupsReducer from "../modules/notifyGroupsSlice";
import ownersReducer from "../modules/ownersSlice";
import reportReducer from "../modules/reportSlice";
import securityGroupsReducer from "../modules/securityGroupsSlice";
import userReducer from "../modules/userSlice";
import usersReducer from "../modules/usersSlice";
import walkReducer from "../modules/walkSlice";
import { APIKEY } from "./constants";
import { listenerMiddleware } from "./listenerMiddleware";

/**
 * Combine all the reducers
 */
const combinedReducer = combineReducers({
  analytics: analyticsReducer,
  categories: categoriesReducer,
  collaboration: collaborationReducer,
  customer: customerReducer,
  documents: documentsReducer,
  documentStages: documentStagesReducer,
  documentStates: documentStatesReducer,
  documentTypes: documentTypesReducer,
  errorCodes: errorCodesReducer,
  esign: esignReducer,
  eventCustom: eventCustomReducer,
  interviewLog: interviewLogReducer,
  loading: loadingReducer,
  mainMenu: mainMenuReducer,
  matters: mattersReducer,
  matterDocuments: matterDocumentsReducer,
  matterDocumentTypes: matterDocumentTypesReducer,
  matterTags: matterTagsReducer,
  matterTasks: matterTasksReducer,
  mattersTimeline: mattersTimelineReducer,
  matterTypes: matterTypesReducer,
  navBar: navBarReducer,
  notifications: notificationsReducer,
  notifyGroups: notifyGroupsReducer,
  owners: ownersReducer,
  report: reportReducer,
  securityGroups: securityGroupsReducer,
  user: userReducer,
  users: usersReducer,
  walk: walkReducer,
});

/**
 * The root reducer
 * @param state The full root state object
 * @param action Any actions that are dispatched
 * @returns Reducer
 */
const rootReducer: Reducer = (state: RootState, action: UnknownAction) => {
  // For any logout requests, reinitialise the store
  if (
    action.type === "user/getLogout/fulfilled" ||
    (action.type === "user/updateIsLoggedIn" && action.payload === false)
  ) {
    state = {} as RootState;
    localStorage.removeItem(APIKEY);
  }

  return combinedReducer(state, action);
};

/**
 * Setup the store with the state sync middleware
 * @param preloadedState Any preloaded state to initialise
 * @returns Store
 */
export const setupStore = (preloadedState?: RootState | {}) => {
  // Only sync updates on login, logout and user details actions
  const config = {
    whitelist: ["user/getLogout/fulfilled", "user/updateIsLoggedIn", "user/getUserDetails/fulfilled"],
  };

  const store = configureStore({
    reducer: rootReducer,
    preloadedState,
    // Sync redux state across tabs
    middleware: (getDefaultMiddleware) => {
      return getDefaultMiddleware()
        .prepend(listenerMiddleware.middleware)
        .concat(createStateSyncMiddleware(config) as unknown as ReturnType<typeof getDefaultMiddleware>);
    },
  });

  // A new tab initialises state with existing tab
  initStateWithPrevTab(store);

  return store;
};

export type RootState = ReturnType<typeof combinedReducer>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
