import { createListenerMiddleware, isAnyOf } from "@reduxjs/toolkit";
import {
  fetchDocumentAnalyticsFiltersEffect,
  fetchMatterAnalyticsFiltersEffect,
  fetchPackagedReportFiltersEffect,
  postDocumentTypeReportFilters,
  postMatterTypeReportFilters,
  postPackagedReportFilters,
} from "../modules/analyticsSlice";
import {
  postAddCollaborator,
  postDeleteCollaborator,
  postSendCollaborationLink,
  refreshCollaborationEffect,
} from "../modules/collaborationSlice";
import {
  fetchDocumentFiltersEffect,
  fetchDocumentsEffect,
  getDocumentFilters,
  postDocuments,
  postDocumentsById,
} from "../modules/documentsSlice";
import { fetchDocumentTypeStagesEffect, postDocumentTypeStages } from "../modules/documentTypesSlice";
import {
  fetchMatterDocumentsByDocumentIdEffect,
  fetchMatterDocumentsEffect,
  postMatterDocuments,
  postMatterDocumentsByDocumentId,
} from "../modules/matterDocumentsSlice";
import {
  fetchLinkedMattersEffect,
  fetchMatterFiltersEffect,
  fetchMattersEffect,
  getMattersFilters,
  postLinkedMatters,
  postMatters,
  postMattersById,
} from "../modules/mattersSlice";
import {
  fetchNotificationsEffect,
  fetchTaskNotificationsEffect,
  getOverdueNotification,
  getPendingNotification,
  getTaskNotification,
} from "../modules/notificationsSlice";
import { fetchNotifyGroupUsersEffect, postNotifyGroupUsers } from "../modules/notifyGroupsSlice";

export const listenerMiddleware = createListenerMiddleware();

// Fetch document types and owners when the documents are fetched
listenerMiddleware.startListening({
  matcher: isAnyOf(postDocuments.fulfilled, postDocumentsById.fulfilled),
  effect: fetchDocumentsEffect,
});

// Fetch document filters items when the document filters are fetched
listenerMiddleware.startListening({
  actionCreator: getDocumentFilters.fulfilled,
  effect: fetchDocumentFiltersEffect,
});

// Fetch matter type IDs and owners when the matters are fetched
listenerMiddleware.startListening({
  matcher: isAnyOf(postMatters.fulfilled, postMattersById.fulfilled),
  effect: fetchMattersEffect,
});

// Fetch matter filters items when the matter filters are fetched
listenerMiddleware.startListening({
  actionCreator: getMattersFilters.fulfilled,
  effect: fetchMatterFiltersEffect,
});

// Fetch packaged report filters items when the packaged report filters are fetched
listenerMiddleware.startListening({
  actionCreator: postPackagedReportFilters.fulfilled,
  effect: fetchPackagedReportFiltersEffect,
});

// Fetch document analytics custom export filters items when the custom export filters are fetched
listenerMiddleware.startListening({
  actionCreator: postDocumentTypeReportFilters.fulfilled,
  effect: fetchDocumentAnalyticsFiltersEffect,
});

// Fetch matter documents doc types and owners when the matter documents are fetched
listenerMiddleware.startListening({
  actionCreator: postMatterDocuments.fulfilled,
  effect: fetchMatterDocumentsEffect,
});

// Fetch matter documents doc types and owners when the matter documents are fetched by document ID
listenerMiddleware.startListening({
  actionCreator: postMatterDocumentsByDocumentId.fulfilled,
  effect: fetchMatterDocumentsByDocumentIdEffect,
});

// Fetch linked matters matter types and owners when the linked matters are fetched
listenerMiddleware.startListening({
  actionCreator: postLinkedMatters.fulfilled,
  effect: fetchLinkedMattersEffect,
});

// Fetch notifications artifacts when the notifications are fetched
listenerMiddleware.startListening({
  matcher: isAnyOf(getOverdueNotification.fulfilled, getPendingNotification.fulfilled),
  effect: fetchNotificationsEffect,
});

// Fetch collaboration object on add / delete of a collaborator or sending a collaboration link
listenerMiddleware.startListening({
  matcher: isAnyOf(
    postAddCollaborator.fulfilled,
    postDeleteCollaborator.fulfilled,
    postSendCollaborationLink.fulfilled,
  ),
  effect: refreshCollaborationEffect,
});

// Fetch notifications artifacts when Task notifications are fetched
listenerMiddleware.startListening({
  actionCreator: getTaskNotification.fulfilled,
  effect: fetchTaskNotificationsEffect,
});

// Fetch owners when the matter type report filters are fetched
listenerMiddleware.startListening({
  actionCreator: postMatterTypeReportFilters.fulfilled,
  effect: fetchMatterAnalyticsFiltersEffect,
});

// Fetch users when the notify group users are fetched
listenerMiddleware.startListening({
  actionCreator: postNotifyGroupUsers.fulfilled,
  effect: fetchNotifyGroupUsersEffect,
});

// Fetch document stages after fetching document type stage ids
listenerMiddleware.startListening({
  actionCreator: postDocumentTypeStages.fulfilled,
  effect: fetchDocumentTypeStagesEffect,
});
