import { lazy, Suspense } from "react";
import { cMonoColorType, cSemanticColorType, cSizeType, cThemeColorType } from "../../app/constants";
import Div from "../Div/Div";

// Non lazy loaded icons
import ActionsMenu from "./Icons/ActionsMenu";
import Add from "./Icons/Add";
import Administration from "./Icons/Administration";
import Alerts from "./Icons/Alerts";
import Analytics from "./Icons/Analytics";
import Attachment from "./Icons/Attachment";
import Close from "./Icons/Close";
import Creation from "./Icons/Creation";
import Delete from "./Icons/Delete";
import Document from "./Icons/Document";
import Documents from "./Icons/Documents";
import DocumentTerminated from "./Icons/DocumentTerminated";
import Edit from "./Icons/Edit";
import Folder from "./Icons/Folder";
import FolderOpen from "./Icons/FolderOpen";
import Inaccessible from "./Icons/Inaccessible";
import Info from "./Icons/Info";
import Logout from "./Icons/Logout";
import MainMenu from "./Icons/MainMenu";
import MatterMap from "./Icons/MatterMap";
import Milestone from "./Icons/Milestone";
import Note from "./Icons/Note";
import NotificationDisabled from "./Icons/NotificationDisabled";
import NotificationPending from "./Icons/NotificationPending";
import NotificationSent from "./Icons/NotificationSent";
import Sign from "./Icons/Sign";
import SigningCancelled from "./Icons/SigningCancelled";
import SigningComplete from "./Icons/SigningComplete";
import SigningInProgress from "./Icons/SigningInProgress";
import Support from "./Icons/Support";
import TransferOwnership from "./Icons/TransferOwnership";
import UpdateDocument from "./Icons/UpdateDocument";
import UploadDocument from "./Icons/UploadDocument";
import UserNotified from "./Icons/UserNotified";
import VisibilityOff from "./Icons/VisibilityOff";
import VisibilityOn from "./Icons/VisibilityOn";

// Lazy loaded icons

const ApiTask = lazy(() => import("./Icons/ApiTask"));
const Email = lazy(() => import("./Icons/Email"));
const Home = lazy(() => import("./Icons/Home"));
const Search = lazy(() => import("./Icons/Search"));
const Reload = lazy(() => import("./Icons/Reload"));
const RefreshGraph = lazy(() => import("./Icons/RefreshGraph"));
const Back = lazy(() => import("./Icons/Back"));
const Up = lazy(() => import("./Icons/Up"));
const Right = lazy(() => import("./Icons/Right"));
const Next = lazy(() => import("./Icons/Next"));
const Down = lazy(() => import("./Icons/Down"));
const Previous = lazy(() => import("./Icons/Previous"));
const UnsignedDocument = lazy(() => import("./Icons/UnsignedDocument"));
const SignedDocument = lazy(() => import("./Icons/SignedDocument"));
const Rename = lazy(() => import("./Icons/Rename"));
const UpdateTag = lazy(() => import("./Icons/UpdateTag"));
const Filters = lazy(() => import("./Icons/Filters"));
const NotificationTime = lazy(() => import("./Icons/NotificationTime"));
const User = lazy(() => import("./Icons/User"));
const UserRoleAdmin = lazy(() => import("./Icons/UserRoleAdmin"));
const AddUser = lazy(() => import("./Icons/AddUser"));
const UserEnable = lazy(() => import("./Icons/UserEnable"));
const UserDisable = lazy(() => import("./Icons/UserDisable"));
const ResetPassword = lazy(() => import("./Icons/ResetPassword"));
const Notice = lazy(() => import("./Icons/Notice"));
const Unlink = lazy(() => import("./Icons/Unlink"));
const Help = lazy(() => import("./Icons/Help"));
const Date = lazy(() => import("./Icons/Date"));
const AddEvent = lazy(() => import("./Icons/AddEvent"));
const AddNote = lazy(() => import("./Icons/AddNote"));
const Clone = lazy(() => import("./Icons/Clone"));
const Start = lazy(() => import("./Icons/Start"));
const Stages = lazy(() => import("./Icons/Stages"));
const Pause = lazy(() => import("./Icons/Pause"));
const WordDocument = lazy(() => import("./Icons/WordDocument"));
const PDFDocument = lazy(() => import("./Icons/PDFDocument"));
const InterviewLog = lazy(() => import("./Icons/InterviewLog"));
const Success = lazy(() => import("./Icons/Success"));
const UpdateStatus = lazy(() => import("./Icons/UpdateStatus"));
const Warning = lazy(() => import("./Icons/Warning"));
const DrawerExpandRight = lazy(() => import("./Icons/DrawerExpandRight"));
const MimeAudio = lazy(() => import("./Icons/MimeAudio"));
const MimeDocx = lazy(() => import("./Icons/MimeDocx"));
const MimePptx = lazy(() => import("./Icons/MimePptx"));
const MimeEps = lazy(() => import("./Icons/MimeEps"));
const MimeExe = lazy(() => import("./Icons/MimeExe"));
const MimeHtml = lazy(() => import("./Icons/MimeHtml"));
const MimeImage = lazy(() => import("./Icons/MimeImage"));
const MimePdf = lazy(() => import("./Icons/MimePdf"));
const MimeTxt = lazy(() => import("./Icons/MimeTxt"));
const MimeWord = lazy(() => import("./Icons/MimeWord"));
const MimeXlsx = lazy(() => import("./Icons/MimeXlsx"));
const MimeZip = lazy(() => import("./Icons/MimeZip"));
const SystemGeneratedEvent = lazy(() => import("./Icons/SystemGeneratedEvent"));
const UserGeneratedEvent = lazy(() => import("./Icons/UserGeneratedEvent"));
const Log = lazy(() => import("./Icons/Log"));
const Approve = lazy(() => import("./Icons/Approve"));
const Drag = lazy(() => import("./Icons/Drag"));
const Group = lazy(() => import("./Icons/Group"));
const TasksCompleted = lazy(() => import("./Icons/TasksCompleted"));
const Collaboration = lazy(() => import("./Icons/Collaboration"));
const WordWeb = lazy(() => import("./Icons/WordWeb"));
const ExcelWeb = lazy(() => import("./Icons/ExcelWeb"));
const PowerPointWeb = lazy(() => import("./Icons/PowerPointWeb"));
const Reassign = lazy(() => import("./Icons/Reassign"));
const AddNotificationGroup = lazy(() => import("./Icons/AddNotificationGroup"));

// Available icons

export enum EIcon {
  Add = "add",
  Alerts = "alerts",
  ApiTask = "apiTask",
  Attachment = "attachment",
  Email = "email",
  Folder = "folder",
  FolderOpen = "folderOpen",
  Home = "home",
  MatterMap = "matterMap",
  Documents = "documents",
  Analytics = "analytics",
  Info = "info",
  Search = "search",
  Reload = "reload",
  RefreshGraph = "refreshGraph",
  Back = "back",
  Up = "up",
  Right = "right",
  Next = "next",
  Down = "down",
  Previous = "previous",
  Collaboration = "collaboration",
  Creation = "creation",
  Document = "document",
  UpdateDocument = "updateDocument",
  UploadDocument = "uploadDocument",
  UnsignedDocument = "unsignedDocument",
  SignedDocument = "signedDocument",
  UpdateTag = "updateTag",
  Filters = "filters",
  NotificationTime = "notificationTime",
  User = "user",
  AddUser = "addUser",
  ResetPassword = "resetPassword",
  Notice = "notice",
  Logout = "logout",
  Unlink = "unlink",
  Help = "help",
  Date = "date",
  AddEvent = "addEvent",
  AddNote = "addNote",
  Clone = "clone",
  Administration = "administration",
  Start = "start",
  Stages = "stages",
  Pause = "pause",
  WordDocument = "wordDocument",
  PDFDocument = "pdfDocument",
  TransferOwnership = "transferOwnership",
  InterviewLog = "interviewLog",
  Success = "success",
  UpdateStatus = "updateStatus",
  Edit = "edit",
  Note = "note",
  Delete = "delete",
  Warning = "warning",
  Inaccessible = "inaccessible",
  Close = "close",
  NotificationSent = "notificationSent",
  NotificationPending = "notificationPending",
  NotificationDisabled = "notificationDisabled",
  ActionsMenu = "actionsMenu",
  VisibilityOn = "visibilityOn",
  VisibilityOff = "visibilityOff",
  MainMenu = "menu",
  Support = "support",
  UserEnable = "userEnable",
  UserDisable = "userDisable",
  UserRoleAdmin = "userRoleAdmin",
  DrawerExpandRight = "drawerExpandRight",
  MimeAudio = "mimeAudio",
  MimeDocx = "mimeDocx",
  MimePptx = "mimePptx",
  MimeEps = "mimeEps",
  MimeExe = "mimeExe",
  MimeHtml = "mimeHtml",
  MimeImage = "mimeImage",
  MimePdf = "mimePdf",
  MimeTxt = "mimeTxt",
  MimeWord = "mimeWord",
  MimeXlsx = "mimeXlsx",
  MimeZip = "mimeZip",
  UserGeneratedEvent = "userGeneratedEvent",
  SystemGeneratedEvent = "systemGeneratedEvent",
  UserNotified = "userNotified",
  Log = "log",
  DocumentTerminated = "documentTerminated",
  Sign = "sign",
  Approve = "approve",
  Drag = "drag",
  Milestone = "milestone",
  Group = "group",
  TasksCompleted = "tasksCompleted",
  SigningInProgress = "signingInProgress",
  SigningComplete = "signingComplete",
  SigningCancelled = "signingCancelled",
  Rename = "rename",
  WordWeb = "wordWeb",
  ExcelWeb = "excelWeb",
  PowerPointWeb = "powerpointWeb",
  Wopi = "wopi",
  Reassign = "reassign",
  AddNotificationGroup = "addNotificationGroup",
}

// Icon component interface
export interface IIcon {
  icon: EIcon;
  size?: cSizeType;
  color?: cThemeColorType | cMonoColorType | cSemanticColorType;
  className?: string;
}

// Internal icon component interface
export interface IIconInternal {
  size: cSizeType;
  color: cThemeColorType | cMonoColorType | cSemanticColorType;
  className?: string;
}

// Mime icon component interface
export interface IIconMime {
  size: cSizeType;
  className?: string;
}

/**
 * SVG icon wrapper component
 * @param icon      The type of icon to display
 * @param size      The size of the icon
 * @param color     The color of the icon
 * @param className Custom class
 * @returns JSX.Element
 */
function Icon({ icon, size = cSizeType.Medium, color = cMonoColorType.Dark, className }: IIcon): JSX.Element {
  // Return the icon component (lazy loaded)
  switch (icon) {
    case EIcon.Add:
      return (
        <Suspense fallback={<Div />}>
          <Add size={size} color={color} className={className} />
        </Suspense>
      );
    case EIcon.Alerts:
      return <Alerts size={size} color={color} className={className} />;
    case EIcon.ApiTask:
      return (
        <Suspense fallback={<Div />}>
          <ApiTask size={size} color={color} className={className} />
        </Suspense>
      );
    case EIcon.Attachment:
      return (
        <Suspense fallback={<Div />}>
          <Attachment size={size} color={color} className={className} />
        </Suspense>
      );
    case EIcon.Email:
      return (
        <Suspense fallback={<Div />}>
          <Email size={size} color={color} className={className} />
        </Suspense>
      );
    case EIcon.Folder:
      return (
        <Suspense fallback={<Div />}>
          <Folder size={size} color={color} className={className} />
        </Suspense>
      );
    case EIcon.FolderOpen:
      return (
        <Suspense fallback={<Div />}>
          <FolderOpen size={size} color={color} className={className} />
        </Suspense>
      );
    case EIcon.Home:
      return (
        <Suspense fallback={<Div />}>
          <Home size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.MatterMap:
      return (
        <Suspense fallback={<Div />}>
          <MatterMap size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Documents:
      return (
        <Suspense fallback={<Div />}>
          <Documents size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Analytics:
      return (
        <Suspense fallback={<Div />}>
          <Analytics size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Info:
      return (
        <Suspense fallback={<Div />}>
          <Info size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Search:
      return (
        <Suspense fallback={<Div />}>
          <Search size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Reload:
      return (
        <Suspense fallback={<Div />}>
          <Reload size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.RefreshGraph:
      return (
        <Suspense fallback={<Div />}>
          <RefreshGraph size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Back:
      return (
        <Suspense fallback={<Div />}>
          <Back size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Up:
      return (
        <Suspense fallback={<Div />}>
          <Up size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Right:
      return (
        <Suspense fallback={<Div />}>
          <Right size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Next:
      return (
        <Suspense fallback={<Div />}>
          <Next size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Down:
      return (
        <Suspense fallback={<Div />}>
          <Down size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Previous:
      return (
        <Suspense fallback={<Div />}>
          <Previous size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Collaboration:
      return (
        <Suspense fallback={<Div />}>
          <Collaboration size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Creation:
      return (
        <Suspense fallback={<Div />}>
          <Creation size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Document:
      return (
        <Suspense fallback={<Div />}>
          <Document size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UpdateDocument:
      return (
        <Suspense fallback={<Div />}>
          <UpdateDocument size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UploadDocument:
      return (
        <Suspense fallback={<Div />}>
          <UploadDocument size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UnsignedDocument:
      return (
        <Suspense fallback={<Div />}>
          <UnsignedDocument size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.SignedDocument:
      return (
        <Suspense fallback={<Div />}>
          <SignedDocument size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UpdateTag:
      return (
        <Suspense fallback={<Div />}>
          <UpdateTag size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Filters:
      return (
        <Suspense fallback={<Div />}>
          <Filters size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.NotificationTime:
      return (
        <Suspense fallback={<Div />}>
          <NotificationTime size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.User:
      return (
        <Suspense fallback={<Div />}>
          <User size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.AddUser:
      return (
        <Suspense fallback={<Div />}>
          <AddUser size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UserEnable:
      return (
        <Suspense fallback={<Div />}>
          <UserEnable size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UserDisable:
      return (
        <Suspense fallback={<Div />}>
          <UserDisable size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UserRoleAdmin:
      return (
        <Suspense fallback={<Div />}>
          <UserRoleAdmin size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.ResetPassword:
      return (
        <Suspense fallback={<Div />}>
          <ResetPassword size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Notice:
      return (
        <Suspense fallback={<Div />}>
          <Notice size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Logout:
      return (
        <Suspense fallback={<Div />}>
          <Logout size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Unlink:
      return (
        <Suspense fallback={<Div />}>
          <Unlink size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Help:
      return (
        <Suspense fallback={<Div />}>
          <Help size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Date:
      return (
        <Suspense fallback={<Div />}>
          <Date size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.AddEvent:
      return (
        <Suspense fallback={<Div />}>
          <AddEvent size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.AddNote:
      return (
        <Suspense fallback={<Div />}>
          <AddNote size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Clone:
      return (
        <Suspense fallback={<Div />}>
          <Clone size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Administration:
      return (
        <Suspense fallback={<Div />}>
          <Administration size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Start:
      return (
        <Suspense fallback={<Div />}>
          <Start size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Stages:
      return (
        <Suspense fallback={<Div />}>
          <Stages size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Pause:
      return (
        <Suspense fallback={<Div />}>
          <Pause size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.WordDocument:
      return (
        <Suspense fallback={<Div />}>
          <WordDocument size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.PDFDocument:
      return (
        <Suspense fallback={<Div />}>
          <PDFDocument size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.TransferOwnership:
      return (
        <Suspense fallback={<Div />}>
          <TransferOwnership size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.InterviewLog:
      return (
        <Suspense fallback={<Div />}>
          <InterviewLog size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Success:
      return (
        <Suspense fallback={<Div />}>
          <Success size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UpdateStatus:
      return (
        <Suspense fallback={<Div />}>
          <UpdateStatus size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Edit:
      return (
        <Suspense fallback={<Div />}>
          <Edit size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Note:
      return (
        <Suspense fallback={<Div />}>
          <Note size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Delete:
      return (
        <Suspense fallback={<Div />}>
          <Delete size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Warning:
      return (
        <Suspense fallback={<Div />}>
          <Warning size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Inaccessible:
      return (
        <Suspense fallback={<Div />}>
          <Inaccessible size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Close:
      return (
        <Suspense fallback={<Div />}>
          <Close size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.NotificationSent:
      return (
        <Suspense fallback={<Div />}>
          <NotificationSent size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.NotificationPending:
      return (
        <Suspense fallback={<Div />}>
          <NotificationPending size={size} className={className} />
        </Suspense>
      );

    case EIcon.NotificationDisabled:
      return (
        <Suspense fallback={<Div />}>
          <NotificationDisabled size={size} className={className} />
        </Suspense>
      );

    case EIcon.ActionsMenu:
      return (
        <Suspense fallback={<Div />}>
          <ActionsMenu size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.MainMenu:
      return (
        <Suspense fallback={<Div />}>
          <MainMenu size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.VisibilityOff:
      return (
        <Suspense fallback={<Div />}>
          <VisibilityOff size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.VisibilityOn:
      return (
        <Suspense fallback={<Div />}>
          <VisibilityOn size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Support:
      return (
        <Suspense fallback={<Div />}>
          <Support size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.DrawerExpandRight:
      return (
        <Suspense fallback={<Div />}>
          <DrawerExpandRight size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.MimeAudio:
      return (
        <Suspense fallback={<Div />}>
          <MimeAudio size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeDocx:
      return (
        <Suspense fallback={<Div />}>
          <MimeDocx size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimePptx:
      return (
        <Suspense fallback={<Div />}>
          <MimePptx size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeEps:
      return (
        <Suspense fallback={<Div />}>
          <MimeEps size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeExe:
      return (
        <Suspense fallback={<Div />}>
          <MimeExe size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeHtml:
      return (
        <Suspense fallback={<Div />}>
          <MimeHtml size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeImage:
      return (
        <Suspense fallback={<Div />}>
          <MimeImage size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimePdf:
      return (
        <Suspense fallback={<Div />}>
          <MimePdf size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeTxt:
      return (
        <Suspense fallback={<Div />}>
          <MimeTxt size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeWord:
      return (
        <Suspense fallback={<Div />}>
          <MimeWord size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeXlsx:
      return (
        <Suspense fallback={<Div />}>
          <MimeXlsx size={size} className={className} />
        </Suspense>
      );

    case EIcon.MimeZip:
      return (
        <Suspense fallback={<Div />}>
          <MimeZip size={size} className={className} />
        </Suspense>
      );

    case EIcon.UserGeneratedEvent:
      return (
        <Suspense fallback={<Div />}>
          <UserGeneratedEvent size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.SystemGeneratedEvent:
      return (
        <Suspense fallback={<Div />}>
          <SystemGeneratedEvent size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.UserNotified:
      return (
        <Suspense fallback={<Div />}>
          <UserNotified size={size} className={className} />
        </Suspense>
      );

    case EIcon.Log:
      return (
        <Suspense fallback={<Div />}>
          <Log size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.DocumentTerminated:
      return (
        <Suspense fallback={<Div />}>
          <DocumentTerminated size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Sign:
      return (
        <Suspense fallback={<Div />}>
          <Sign size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Approve:
      return (
        <Suspense fallback={<Div />}>
          <Approve size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Drag:
      return (
        <Suspense fallback={<Div />}>
          <Drag size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Milestone:
      return (
        <Suspense fallback={<Div />}>
          <Milestone size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Group:
      return (
        <Suspense fallback={<Div />}>
          <Group size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.TasksCompleted:
      return (
        <Suspense fallback={<Div />}>
          <TasksCompleted size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.SigningInProgress:
      return (
        <Suspense fallback={<Div />}>
          <SigningInProgress size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.SigningComplete:
      return (
        <Suspense fallback={<Div />}>
          <SigningComplete size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.SigningCancelled:
      return (
        <Suspense fallback={<Div />}>
          <SigningCancelled size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Rename:
      return (
        <Suspense fallback={<Div />}>
          <Rename size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.WordWeb:
      return (
        <Suspense fallback={<Div />}>
          <WordWeb size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.ExcelWeb:
      return (
        <Suspense fallback={<Div />}>
          <ExcelWeb size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.PowerPointWeb:
      return (
        <Suspense fallback={<Div />}>
          <PowerPointWeb size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.Reassign:
      return (
        <Suspense fallback={<Div />}>
          <Reassign size={size} color={color} className={className} />
        </Suspense>
      );

    case EIcon.AddNotificationGroup:
      return (
        <Suspense fallback={<Div />}>
          <AddNotificationGroup size={size} color={color} className={className} />
        </Suspense>
      );

    default:
      return <></>;
  }
}

export default Icon;
