import { isBoolean, isEmpty } from "lodash";
import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { cRouteType, CUSTOMER_CODE, WALK_RETURN_URL } from "../../../../app/constants";
import { useAppDispatch, useAppSelector } from "../../../../app/hooks";
import { updateCollabIsActive } from "../../../../modules/collaborationSlice";
import { selectAllowDocumentStages } from "../../../../modules/customerSlice";
import {
  deleteDocument,
  ETimelineEntryType,
  IDocument,
  openInformationUpdatedModal,
  postDocuments,
  postDocumentsById,
  postDocumentsByPage,
  postDocumentTimeline,
  postDocumentTransferUsers,
  postDownloadTimelineFile,
  postRenameDocument,
  resetTimelineEntry,
  selectDocumentRenameStatus,
  selectDocuments,
  selectDocumentsFetched,
  selectTimelineEntry,
} from "../../../../modules/documentsSlice";
import { IDocumentType, selectDocumentTypes } from "../../../../modules/documentTypesSlice";
import {
  postESign,
  resetIFrameURL,
  selectActiveESignID,
  selectIFrameURL,
  updateActiveESignID,
} from "../../../../modules/esignSlice";
import {
  postDeleteCustomEvent,
  selectEventCustomError,
  selectEventCustomStatus,
} from "../../../../modules/eventCustomSlice";
import {
  postMatterDocuments,
  postRenameMatterDocument,
  selectMatterDocumentRenameStatus,
  selectMatterDocuments,
} from "../../../../modules/matterDocumentsSlice";
import { selectMatterDocumentTypes } from "../../../../modules/matterDocumentTypesSlice";
import { postMattersById, selectMatters } from "../../../../modules/mattersSlice";
import { IUser, postUsers, selectUsers, selectUsersFetched } from "../../../../modules/usersSlice";
import { EWalkType, postDocumentAmend, postDocumentClone } from "../../../../modules/walkSlice";
import { errorToast } from "../../../../toast/toast";
import { isNotFoundError, isOutdatedContentError, isUnableToViewDocumentError } from "../../../../utils/errors/errors";
import DocumentInformationModal from "./DocumentInformationModal/DocumentInformationModal";

function DocumentInformationModalContainer(): JSX.Element | null {
  const { matterId, id } = useParams<{ matterId?: string; id: string }>();
  const location = useLocation();
  const navigate = useNavigate();
  const { pathname } = location;

  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isMattersDoc, setIsMattersDoc] = useState<boolean | null>(null);
  const [selectedDocument, setSelectedDocument] = useState<IDocument>();
  const [matterReferenceCode, setMatterReferenceCode] = useState("");
  const [documentType, setDocumentType] = useState<IDocumentType>();
  const [owner, setOwner] = useState<IUser>();
  const [isNewEventModalOpen, setIsNewEventModalOpen] = useState(false);
  const [deleteId, setDeleteId] = useState<number | null>(null);
  const [isTransferOwnershipModalOpen, setIsTransferOwnershipModalOpen] = useState(false);
  const [isTerminateDocumentModalOpen, setIsTerminateDocumentModalOpen] = useState(false);
  const [isRenameDocumentModalOpen, setIsRenameDocumentModalOpen] = useState(false);
  const [isAttachSupportingDocModalOpen, setIsAttachSupportingDocModalOpen] = useState(false);
  const [isAddNoteModalOpen, setIsAddNoteModalOpen] = useState<boolean>(false);
  const [isNewESignModalOpen, setIsNewESignModalOpen] = useState(false);
  const [isESignWorkflowModalOpen, setIsESignWorkflowModalOpen] = useState(false);
  const [timelineEntryType, setTimelineEntryType] = useState<ETimelineEntryType | null>();
  const [esignUrn, setESignUrn] = useState<number | null>();
  const [documents, setDocuments] = useState<Record<number, IDocument>>([]);
  const [interviewLogTabRef, setInterviewLogTabRef] = useState<any>(null);
  const [isChangeStageModalOpen, setIsChangeStageModalOpen] = useState<boolean>(false);

  const documentTypes = useAppSelector(isMattersDoc ? selectMatterDocumentTypes : selectDocumentTypes);
  const users = useAppSelector(selectUsers);
  const matters = useAppSelector(selectMatters);
  const timelineEntry = useAppSelector(selectTimelineEntry);
  const primaryDocuments = useAppSelector(selectDocuments);
  const allMatterDocuments = useAppSelector(selectMatterDocuments);
  const eventCustomStatus = useAppSelector(selectEventCustomStatus);
  const eventCustomError = useAppSelector(selectEventCustomError);
  const activeESignID = useAppSelector(selectActiveESignID);
  const iframeURL = useAppSelector(selectIFrameURL);
  const areUsersFetched = useAppSelector(selectUsersFetched);
  const areDocumentsFetched = useAppSelector(selectDocumentsFetched);
  const renameStatus = useAppSelector(isMattersDoc ? selectMatterDocumentRenameStatus : selectDocumentRenameStatus);
  const allowDocumentStages = useAppSelector(selectAllowDocumentStages);

  const dispatch = useAppDispatch();

  // 1 - fetch the timeline entry
  useEffect(() => {
    if (id && !id.includes("?")) {
      // Fetch timeline with the id
      dispatch(postDocumentTimeline(Number(id)))
        .unwrap()
        .catch((error) => {
          const errorCode = parseInt(error, 10);
          // Render more appropriate error messages for privileges and not found errors
          if (isUnableToViewDocumentError(errorCode)) {
            errorToast(`${errorCode}: You do not have access to view this document`);
          } else if (isNotFoundError(errorCode)) {
            errorToast(`${errorCode}: Document not found`);
          } else if (isOutdatedContentError(errorCode)) {
            dispatch(openInformationUpdatedModal());
          } else {
            errorToast(error);
          }

          // Redirect the user
          navigate(`${location.pathname.slice(0, location.pathname.lastIndexOf("/"))}`);
        });
    }
  }, [id]);

  // 2 - fetch the document, set the matters doc state and fetch the users
  useEffect(() => {
    // Get the document and users once timeline entry is fetched
    if (timelineEntry) {
      if (timelineEntry.groupingID) {
        dispatch(postMatterDocuments(timelineEntry.groupingID));
        dispatch(deleteDocument(id as string));
        dispatch(postMattersById({ ids: [timelineEntry.groupingID] }));
        setIsMattersDoc(true);
      } else {
        dispatch(postDocumentsById({ ids: [parseInt(id as string, 10)] }));
        setIsMattersDoc(false);
      }

      // Fetch all documents if it's the document timeline
      if (pathname.includes(cRouteType.Document) && !areDocumentsFetched) {
        dispatch(postDocuments());
      }

      // Fetch all users if they haven't already been fetched
      if (areUsersFetched === false) {
        dispatch(postUsers({}));
      }
    }
  }, [timelineEntry]);

  // 3 - set the documents section (either primary documents or from matter documents)
  useEffect(() => {
    if (timelineEntry && isBoolean(isMattersDoc)) {
      // Get documents from matter *or* primary documents slice
      const documentsSection = isMattersDoc
        ? allMatterDocuments[timelineEntry?.groupingID as number]?.documents
        : primaryDocuments;

      setDocuments(documentsSection);
    }
  }, [primaryDocuments, allMatterDocuments, isMattersDoc, timelineEntry, documents, selectedDocument]);

  // 4 - set document type and owner
  useEffect(() => {
    if (timelineEntry) {
      if (documentTypes && !isEmpty(documentTypes)) {
        setDocumentType(documentTypes[timelineEntry.documentTypeID]);
      }

      if (users && !isEmpty(users)) {
        setOwner(users?.[timelineEntry.ownerID]);
      }
    }
  }, [timelineEntry, documentTypes, users]);

  // 5 - pick the selected document
  useEffect(() => {
    if (!isEmpty(documents) && documents[Number(id)]) {
      setSelectedDocument(documents[Number(id)]);
    }
  }, [documents]);

  // 6 - open the modal
  useEffect(() => {
    if (timelineEntry && selectedDocument) {
      setIsOpen(true);
    }
  }, [timelineEntry, selectedDocument]);

  // Reset on unmount
  useEffect(() => {
    return () => {
      setIsOpen(false);
      setSelectedDocument(undefined);
      setIsMattersDoc(null);
      setDocuments([]);
      dispatch(resetTimelineEntry());
    };
  }, []);

  /**
   * Handle event deletion
   */
  async function handleDeleteEvent() {
    try {
      await dispatch(postDeleteCustomEvent({ id: deleteId as number })).unwrap();
      dispatch(postDocumentTimeline(Number(id)));
      handleDeleteModalClose();
      toast("Event deleted");
    } catch (error) {
      console.error(error);
    }
  }

  /**
   * Handle new esign
   * @param type     The timeline entry type
   * @param urn      The esign urn
   * @param esignID  The esign id
   * returns void
   */
  async function handleESignEvent(type: ETimelineEntryType, urn?: number, esignID?: number) {
    setTimelineEntryType(type);
    setESignUrn(urn);
    if (esignID) {
      dispatch(updateActiveESignID(esignID));
      setIsESignWorkflowModalOpen(true);
    } else {
      setIsNewESignModalOpen(true);
    }
  }

  /**
   * Close the new esign modal
   * @param canGoToESignWorkflow Should we go to the ESign workflow?
   * returns void
   */
  function handleNewESignModalClose(canGoToESignWorkflow: boolean): void {
    setIsNewESignModalOpen(false);
    setTimelineEntryType(null);
    setESignUrn(null);
    if (canGoToESignWorkflow) {
      setIsESignWorkflowModalOpen(true);
    }
  }

  /**
   * Close the esign workflow modal
   */
  function handleESignWorkflowModalClose(): void {
    dispatch(updateActiveESignID(null));
    setIsESignWorkflowModalOpen(false);
  }

  /**
   * Handles file download
   * @param fileID The file id
   */
  function handleFileDownload(fileID: number) {
    dispatch(postDownloadTimelineFile({ documentID: Number(id), fileID }));
  }

  useEffect(() => {
    // Set the matter ref code using the matter id url param or document groupingID
    if (matterId) {
      setMatterReferenceCode(matters[parseInt(matterId)]?.referenceCode);
    } else if (selectedDocument?.groupingID) {
      setMatterReferenceCode(matters[selectedDocument?.groupingID as number]?.referenceCode);
    }
  }, [matters, selectedDocument]);

  /**
   * Close the delete modal
   */
  function handleDeleteModalClose() {
    setDeleteId(null);
  }

  /**
   * Open the "are you sure?" modal
   * @param eventId Event ID
   */
  function handleDeleteBtnClick(eventId: number) {
    setDeleteId(eventId);
  }

  /**
   * Opens the interview log in a new window
   * @param walkID The walk id
   */
  function openInterviewLogWindow(walkID: number) {
    // Include matter id if exists
    const url = timelineEntry?.groupingID
      ? window.location.origin + `/${cRouteType.InterviewLog}/${walkID}/${documentType?.id}/${timelineEntry.groupingID}`
      : window.location.origin + `/${cRouteType.InterviewLog}/${walkID}/${documentType?.id}`;
    const interviewLogTabRef = window.open(url, walkID.toString(), "toolbar=0,location=0,menubar=0");
    setInterviewLogTabRef(interviewLogTabRef);
  }

  /**
   * Opens the interview log in a new window or focuses the current one
   * @param walkID The walk id
   */
  function handleInterviewLog(walkID: number) {
    // Make sure only one window can be opened
    if (interviewLogTabRef === null || interviewLogTabRef.closed) {
      openInterviewLogWindow(walkID);
    } else if (interviewLogTabRef && interviewLogTabRef.name === walkID.toString()) {
      interviewLogTabRef.focus();
    } else {
      interviewLogTabRef.close();
      setInterviewLogTabRef(null);
      openInterviewLogWindow(walkID);
    }
  }

  /**
   * Opens the add note modal
   */
  function handleAddNoteClick(): void {
    setIsAddNoteModalOpen(true);
  }

  /**
   * Closes the add note modal
   */
  function handleAddNoteModalClose(): void {
    setIsAddNoteModalOpen(false);
  }

  /**
   * Close the change stage modal
   */
  function handleChangeStageModalClose() {
    setIsChangeStageModalOpen(false);
  }

  /**
   * Handles transfer ownership click
   */
  async function handleTransferOwnershipClick(): Promise<void> {
    await dispatch(postDocumentTransferUsers(Number(id))).unwrap();
    setIsTransferOwnershipModalOpen(true);
  }

  /**
   * Close the transfer ownership modal
   */
  function handleTransferOwnershipModalClose(): void {
    setIsTransferOwnershipModalOpen(false);
  }

  /**
   * Start update interview data walk
   * @param id             The id of the document
   * @param action         The amend action
   * @param compositeState The composite state
   */
  async function handleDocumentAmend(id: number, action: EWalkType, compositeState: number) {
    try {
      await dispatch(postDocumentAmend({ id, action, compositeState })).unwrap();
      if (pathname.includes(cRouteType.Notifications)) {
        localStorage.setItem(WALK_RETURN_URL, cRouteType.Notifications);
      } else if (isMattersDoc) {
        localStorage.setItem(WALK_RETURN_URL, cRouteType.Matters);
      } else {
        localStorage.setItem(WALK_RETURN_URL, cRouteType.Document);
      }

      if (action === EWalkType.Collaboration) {
        dispatch(updateCollabIsActive(true));
      }
    } catch (error: any) {
      console.error(error);
      const errorCode = parseInt(error);
      if (isOutdatedContentError(errorCode)) {
        dispatch(openInformationUpdatedModal());
      }
    }
  }

  /**
   * Start document clone walk
   * @param id The id of the document to be cloned
   */
  async function handleCloneDocumentClick(id: number) {
    await dispatch(postDocumentClone({ id })).unwrap();

    if (pathname.includes(cRouteType.Notifications)) {
      localStorage.setItem(WALK_RETURN_URL, cRouteType.Notifications);
    } else if (pathname.includes(cRouteType.Matters)) {
      localStorage.setItem(WALK_RETURN_URL, cRouteType.Matters);
    } else {
      localStorage.setItem(WALK_RETURN_URL, cRouteType.Document);
    }
  }

  /**
   * Opens the add event modal
   */
  function handleAddEvent(): void {
    setIsNewEventModalOpen(true);
  }

  /**
   * Close the new event modal
   */
  function handleNewEventModalClose() {
    setIsNewEventModalOpen(false);
  }

  /**
   * Function called on update success
   */
  function onUpdateSuccess() {
    dispatch(postDocumentsById({ ids: [Number(id as string)], force: true }));
    dispatch(postDocumentTimeline(Number(id)));
    if (matterId) {
      dispatch(postMatterDocuments(Number(matterId)));
    }
  }

  /**
   * Handles attach supporting file
   */
  function handleAttachSupportingDocClick() {
    setIsAttachSupportingDocModalOpen(true);
  }

  /**
   * Close the attach supporting file modal
   */
  function handleAttachSupportingDocModalClose(): void {
    setIsAttachSupportingDocModalOpen(false);
  }

  /**
   * Handles terminate document click
   */
  async function handleTerminateDocumentClick(): Promise<void> {
    setIsTerminateDocumentModalOpen(true);
  }

  /**
   * Close the terminate document modal
   */
  function handleTerminateDocumentModalClose(): void {
    setIsTerminateDocumentModalOpen(false);
  }

  /**
   * Handles rename document
   * @param documentID The document id
   */
  function handleRenameDocument() {
    setIsRenameDocumentModalOpen(true);
  }

  /**
   * Handles rename document
   * @param description The new document description
   */
  async function handleRenameDocumentSave(description: string): Promise<void> {
    if (isMattersDoc) {
      await dispatch(
        postRenameMatterDocument({
          ID: Number(id) as number,
          description,
          matterID: matterId ? Number(matterId) : (selectedDocument?.groupingID as number),
        }),
      ).unwrap();
    } else {
      await dispatch(postRenameDocument({ ID: Number(id) as number, description })).unwrap();
    }
    dispatch(postDocumentTimeline(Number(id)));
    setIsRenameDocumentModalOpen(false);
  }

  /**
   * Close the rename document modal
   */
  function handleRenameDocumentModalClose() {
    setIsRenameDocumentModalOpen(false);
  }

  /**
   * Handle the esign iframe modal close
   * @param canCloseESignWorkflowModal Can we close the esign workflow modal?
   */
  function handleESignIFrameModalClose(canCloseESignWorkflowModal: boolean) {
    onUpdateSuccess();
    dispatch(resetIFrameURL());
    if (canCloseESignWorkflowModal) {
      setIsESignWorkflowModalOpen(false);
    } else if (activeESignID) {
      dispatch(postESign(activeESignID));
    }
  }

  /**
   * Handles close
   */
  function handleClose() {
    const prevUrl = `${location.pathname.slice(0, location.pathname.lastIndexOf("/"))}`;

    // If matter document, add the customer code onto the URL
    if (isMattersDoc) {
      const customerCode = localStorage.getItem(CUSTOMER_CODE);
      navigate(`${prevUrl}?ccof=${customerCode}`);
    } else {
      navigate(prevUrl);
    }
  }

  /**
   * Handle change stage click
   */
  function handleChangeStageClick() {
    setIsChangeStageModalOpen(true);
  }

  /**
   * Reload the matter documents or first documents page to refresh the collab in progress state
   */
  async function reloadDocuments() {
    if (isMattersDoc) {
      await dispatch(postMatterDocuments(Number(matterId))).unwrap();
    } else {
      await dispatch(postDocumentsByPage(1)).unwrap();
    }
  }

  // Breadcrumb links
  const breadcrumbLinks = isMattersDoc
    ? [
        {
          id: "Matters",
          label: "Matters",
          url: "/matters",
        },
        {
          id: matterReferenceCode,
          label: matterReferenceCode,
          url: `/matters/${selectedDocument?.groupingID}`,
        },
        {
          id: `${id}`,
          label: id,
          url: `${location.pathname}`,
        },
      ]
    : [
        {
          id: "Documents",
          label: "Documents",
          url: "/document",
        },
        {
          id: `${id}`,
          label: id,
          url: `${location.pathname}`,
        },
      ];

  return (
    <DocumentInformationModal
      id={Number(id)}
      isOpen={isOpen}
      isAddNoteModalOpen={isAddNoteModalOpen}
      isNewEventModalOpen={isNewEventModalOpen}
      isTransferOwnershipModalOpen={isTransferOwnershipModalOpen}
      isTerminateDocumentModalOpen={isTerminateDocumentModalOpen}
      isAttachSupportingDocModalOpen={isAttachSupportingDocModalOpen}
      isNewESignModalOpen={isNewESignModalOpen}
      isESignWorkflowModalOpen={isESignWorkflowModalOpen}
      isRenameDocumentModalOpen={isRenameDocumentModalOpen}
      handleClose={handleClose}
      handleTransferOwnershipClick={handleTransferOwnershipClick}
      handleAddNoteClick={handleAddNoteClick}
      handleAddNoteModalClose={handleAddNoteModalClose}
      handleAddEvent={handleAddEvent}
      handleNewEventModalClose={handleNewEventModalClose}
      handleDocumentAmend={handleDocumentAmend}
      handleAttachSupportingDocClick={handleAttachSupportingDocClick}
      handleAttachSupportingDocModalClose={handleAttachSupportingDocModalClose}
      handleTerminateDocumentClick={handleTerminateDocumentClick}
      handleTerminateDocumentModalClose={handleTerminateDocumentModalClose}
      handleRenameDocument={handleRenameDocument}
      handleRenameDocumentModalClose={handleRenameDocumentModalClose}
      handleRenameDocumentSave={handleRenameDocumentSave}
      handleCloneDocumentClick={handleCloneDocumentClick}
      handleDeleteBtnClick={handleDeleteBtnClick}
      handleDeleteModalClose={handleDeleteModalClose}
      handleDeleteEvent={handleDeleteEvent}
      handleFileDownload={handleFileDownload}
      handleInterviewLog={handleInterviewLog}
      handleESignEvent={handleESignEvent}
      handleESignWorkflowModalClose={handleESignWorkflowModalClose}
      handleNewESignModalClose={handleNewESignModalClose}
      handleESignIFrameModalClose={handleESignIFrameModalClose}
      handleTransferOwnershipModalClose={handleTransferOwnershipModalClose}
      handleChangeStageClick={handleChangeStageClick}
      onUpdateSuccess={onUpdateSuccess}
      selectedDocument={selectedDocument}
      timelineEntry={timelineEntry}
      timelineEntryType={timelineEntryType}
      documentTypes={documentTypes}
      documentType={documentType}
      documents={documents}
      matterReferenceCode={matterReferenceCode}
      eventCustomStatus={eventCustomStatus}
      renameStatus={renameStatus}
      eventCustomError={eventCustomError}
      deleteId={deleteId}
      esignUrn={esignUrn}
      activeESignID={activeESignID}
      iframeURL={iframeURL}
      owner={owner}
      breadcrumbLinks={breadcrumbLinks}
      reloadDocuments={reloadDocuments}
      allowDocumentStages={allowDocumentStages}
      handleChangeStageModalClose={handleChangeStageModalClose}
      isChangeStageModalOpen={isChangeStageModalOpen}
    />
  );
}

export default DocumentInformationModalContainer;
