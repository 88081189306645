import classNames from "classnames";
import { every } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { cStatusType, cThemeColorType } from "../../../../../app/constants";
import { EPopoverPlacement } from "../../../../../app/types";
import { EButtonVariant } from "../../../../../components/Button/Button";
import Div from "../../../../../components/Div/Div";
import FormField, { cFormFieldType, IFieldOption } from "../../../../../components/FormField/FormField";
import Icon, { EIcon } from "../../../../../components/Icon/Icon";
import Popover from "../../../../../components/Popover/Popover";
import Column from "../../../../../components/Row/Column/Column";
import Row from "../../../../../components/Row/Row";
import Spinner from "../../../../../components/Spinner/Spinner";
import Typography from "../../../../../components/Typography/Typography";
import { ICustomExportFilters, TAdvancedFilter } from "../../../../../modules/analyticsSlice";
import {
  createAdvancedFiltersRows,
  handleSelectAll,
  handleSelectAllAdvanced,
  hasFormChanged,
} from "../../../../../utils/analytics/analytics";
import styles from "../../Analytics.module.scss";
import SaveAsTemplateModal from "../SaveAsTemplateModal/SaveAsTemplateModal";
import AnalyticsAdvancedFilters from "./AnalyticsAdvancedFilters/AnalyticsAdvancedFilters";
import AnalyticsDateFilter from "./AnalyticsDateFilter/AnalyticsDateFilter";
import AnalyticsDateRangeFilter from "./AnalyticsDateRangeFilter/AnalyticsDateRangeFilter";
import AnalyticsDescriptionContainsFieldGroup from "./AnalyticsDescriptionContainsFieldGroup/AnalyticsDescriptionContainsFieldGroup";
import AnalyticsFiltersContainer from "./AnalyticsFiltersContainer/AnalyticsFiltersContainer";
import AnalyticsFiltersHeaderContainer from "./AnalyticsFiltersHeaderContainer/AnalyticsFiltersHeaderContainer";
import AnalyticsOwnersFilter from "./AnalyticsOwnersFilter/AnalyticsOwnersFilter";

interface ICustomExportProps {
  configID: number | null;
  filters: ICustomExportFilters;
  filtersStatus: cStatusType;
  ownerFilterOptions: IFieldOption[];
  stageFilterOptions: IFieldOption[];
  stateFilterOptions: IFieldOption[];
  includeTerminatedValue: boolean;
  documentClassOptions: IFieldOption[];
  dateFilterOptions: IFieldOption[];
  formatOptions: IFieldOption[];
  configName?: string;
  handleBackClick: () => void;
  allowDocumentStages: boolean | undefined;
  runDocumentTypeReportStatus: cStatusType;
  saveDocumentTypeReportTemplateStatus: cStatusType;
  handleSaveTemplateClick: (data: Inputs & { templateName?: string }) => void;
  handleSaveAsTemplateClick: (data: Inputs & { templateName: string }) => void;
  setIsDeleteReportTemplateModalOpen: (isOpen: boolean) => void;
  handleRunReportClick: (data: Inputs) => void;
}

export interface Inputs {
  date: string;
  documentClass: string;
  descriptionContains: { value: string | null; caseSensitive: boolean };
  documentDataContains: { value: string | null; caseSensitive: boolean };
  states?: string[] | null;
  stages?: string[];
  owners?: string[];
  selectAllOwners: "allOwners" | boolean;
  selectAllStages: "allStages" | boolean;
  dateFilter: string;
  startDate: string;
  endDate: string;
  format: string;
  includeTerminated: "includeTerminated" | boolean;
  selectAllAdvancedInclude: "allAdvancedInclude" | boolean;
  selectAllAdvancedFilter: "allAdvancedFilter" | boolean;
  advanced: AdvancedFilterInputs;
  templateName?: string;
}

// Type for the default advanced filter values
export type AdvancedFilterInputs = {
  [key: string]: {
    id: number;
    name: string;
    value: IFieldOption | string | null;
    include: boolean;
    filter: boolean;
    options?: Array<{ label: string; value: string }>;
  };
};

/**
 * The Custom export component
 * @param configID                             The configID of the report
 * @param filters                              The filters
 * @param filtersStatus                        The filters status
 * @param ownerFilterOptions                   The owner filter options
 * @param stageFilterOptions                   The stage filter options
 * @param stateFilterOptions                   The state filter options
 * @param dateFilterOptions                    The date filter options
 * @param documentClassOptions                 The document class options
 * @param formatOptions                        The format options
 * @param configName                           The config name
 * @param handleBackClick                      The handle back click function
 * @param allowDocumentStages                  The allow document stages
 * @param runDocumentTypeReportStatus          The run document type report status
 * @param saveDocumentTypeReportTemplateStatus The save changes status
 * @param handleSaveTemplateClick              The function to handle saving a filters template
 * @param handleSaveAsTemplateClick            The function to handle saving a filters template as a new template
 * @param setIsDeleteReportTemplateModalOpen   The set is delete report template modal open state
 * @param handleRunReportClick                 The handle run report click function
 * @returns JSX.Element
 */

function CustomExport({
  configID,
  filters,
  filtersStatus,
  ownerFilterOptions,
  stageFilterOptions,
  stateFilterOptions,
  dateFilterOptions,
  documentClassOptions,
  formatOptions,
  configName,
  handleBackClick,
  allowDocumentStages,
  runDocumentTypeReportStatus,
  saveDocumentTypeReportTemplateStatus,
  handleSaveTemplateClick,
  handleSaveAsTemplateClick,
  setIsDeleteReportTemplateModalOpen,
  handleRunReportClick,
}: ICustomExportProps): JSX.Element {
  // Is the save as template modal open?
  const [isSaveAsTemplateModalOpen, setIsSaveAsTemplateModalOpen] = useState(false);

  // Set default values for filters as received from API
  const defaultValues = useMemo(() => {
    const advancedFiltersDefaults: AdvancedFilterInputs = {};

    // Loop through each advanced filter and set the default values
    filters?.optionalFilters?.advanced?.forEach((filter: TAdvancedFilter) => {
      // Build the advanced filter object based on the filter type (text or list)
      if ("text" in filter) {
        advancedFiltersDefaults[filter.text.name] = {
          id: filter.text.id,
          name: filter.text.name,
          value: filter.text.value ?? "",
          include: filter.text.include,
          filter: filter.text.filter,
        };
      } else if ("list" in filter) {
        // Check if the object has the expected structure
        if (filter.list && typeof filter.list === "object") {
          const { options, value } = filter.list;
          advancedFiltersDefaults[filter.list.name] = {
            id: filter.list.id,
            name: filter.list.name,
            value: value ? ({ label: value, value } as IFieldOption) : "", // Use IFieldOption for select fields as it is expected by react-select
            include: filter.list.include,
            filter: filter.list.filter,
            options: options.map((option) => ({ label: option, value: option })),
          };
        }
      }
    });

    // Get the stages default value only if document stages are allowed on the customer
    const selectAllStages = allowDocumentStages
      ? filters?.optionalFilters.stages.value.length === filters?.optionalFilters.stages.ids.length
        ? ("allStages" as const)
        : false
      : false;

    return {
      startDate: configID === null ? "" : filters?.startDate || "",
      endDate: configID === null ? "" : filters?.endDate || "",
      documentClass:
        typeof filters?.documentClass?.value === "string" ? filters.documentClass.value.toLowerCase() : "all",
      dateFilter: typeof filters?.dateFilter?.value === "string" ? filters.dateFilter.value : "Last Modified Date",
      format: typeof filters?.format?.value === "string" ? filters.format.value : "xlsx",
      descriptionContains: {
        value: filters?.optionalFilters?.descriptionContains?.value,
        caseSensitive: filters?.optionalFilters?.descriptionContains?.caseSensitive,
      },
      documentDataContains: {
        value: filters?.optionalFilters?.documentDataContains?.value,
        caseSensitive: filters?.optionalFilters?.documentDataContains?.caseSensitive,
      },
      states: filters?.optionalFilters?.states?.value?.map(String),
      stages: filters?.optionalFilters?.stages?.value?.map(String),
      owners: filters?.optionalFilters?.owners?.value?.map(String) || [],
      selectAllOwners:
        filters?.optionalFilters.owners.value.length === filters?.optionalFilters.owners.ids.length
          ? ("allOwners" as const)
          : false,
      selectAllStages,
      includeTerminated: filters?.optionalFilters?.includeTerminated ? ("includeTerminated" as const) : false,
      selectAllAdvancedInclude: filters?.optionalFilters?.advanced?.every((filter: TAdvancedFilter) =>
        "text" in filter ? filter.text.include : filter.list.include,
      ),
      selectAllAdvancedFilter: filters?.optionalFilters?.advanced?.every((filter: TAdvancedFilter) =>
        "text" in filter ? filter.text.filter : filter.list.filter,
      ),
      advanced: advancedFiltersDefaults,
    };
  }, [filters, configID, allowDocumentStages]);

  const {
    control,
    register,
    reset,
    watch,
    getValues,
    setValue,
    handleSubmit,
    trigger,
    formState: { errors },
  } = useForm<Inputs>({
    defaultValues,
  });

  const values = watch();
  const startDate = values.startDate;
  const endDate = values.endDate;
  const [stateFilters, setStateFilters] = useState<IFieldOption[]>(stateFilterOptions);
  const states = stateFilters?.filter((state) => state.label !== "Include Terminated").reverse();
  const [advancedFilterVisibility, setAdvancedFilterVisibility] = useState<{ [key: string]: boolean }>({});
  const [hasChanges, setHasChanges] = useState(false);

  // Set the maximum number of options before scrolling is enabled in a `scrollBox`
  const maxOptions = 8;

  // Reset the defaultValues
  useEffect(() => {
    reset(defaultValues);
  }, [defaultValues, configID]);

  // Watch form fields for changes
  useEffect(() => {
    setHasChanges(hasFormChanged({ defaultValues, currentValues: getValues() }));
  }, [values, defaultValues]);

  // Set state filters to the value received from api
  useEffect(() => {
    setStateFilters(stateFilterOptions);
  }, [stateFilterOptions]);

  // Set case sensitive values for description and document data
  useEffect(() => {
    const descriptionCaseSensitive = filters?.optionalFilters?.descriptionContains?.caseSensitive;
    const documentDataCaseSensitive = filters?.optionalFilters?.documentDataContains?.caseSensitive;
    setValue("descriptionContains.caseSensitive", descriptionCaseSensitive);
    setValue("documentDataContains.caseSensitive", documentDataCaseSensitive);
  }, [filters]);

  // Conditionally show the advanced filter inputs based on the filter? checkbox
  useEffect(() => {
    // Create object to store the visibility state for each advanced filter
    const initialVisibility: { [key: string]: boolean } = {};
    // Loop through each key in the advanced filters' defaultValues as received from API
    Object.keys(defaultValues.advanced).forEach((key) => {
      // Set initial visibility state based on the filter value in default values
      initialVisibility[key] = defaultValues.advanced[key].filter;
    });
    // Update state with the initial visibility values
    setAdvancedFilterVisibility(initialVisibility);
  }, [defaultValues.advanced]);

  useEffect(() => {
    // Set up a subscription to watch for form changes
    const subscription = watch((value, { name, type }) => {
      // Handle visibility of advanced filters
      if (/^advanced\..+\.filter$/.test(name as string)) {
        // Get the filter name from the field name
        const filterName = (name as string).split(".")[1];
        setAdvancedFilterVisibility((prev) => ({
          ...prev, // Spread the previous state
          // Update the visibility state of the targeted field based on the filter value
          [filterName]: !!value.advanced?.[filterName]?.filter,
        }));
      }

      // Only update on change events as others can be automatically triggered
      if (type === "change") {
        switch (true) {
          // Handle "select all" checkbox functionality for owners - check all owners if "All" is selected
          case name === "selectAllOwners":
            handleSelectAll({
              fieldName: "owners",
              selectAllValue: value.selectAllOwners as string,
              itemIds: filters?.optionalFilters.owners?.ids?.map(Number),
              setValue,
            });
            break;
          // Set the "select all" checkbox state based on whether all owners are selected
          case name === "owners":
            // Update "select all" state based on whether all owners are selected
            setValue("selectAllOwners", value.owners?.length === ownerFilterOptions.length ? "allOwners" : false);
            break;
          // Handle "select all" checkbox functionality for stages - check all stages if "All" is selected
          case name === "selectAllStages":
            handleSelectAll({
              fieldName: "stages",
              selectAllValue: value.selectAllStages as string,
              itemIds: filters?.optionalFilters.stages?.ids?.map(Number),
              setValue,
            });
            break;
          case name === "stages":
            // Update "select all" state based on whether all stages are selected
            setValue("selectAllStages", value.stages?.length === stageFilterOptions.length ? "allStages" : false);
            break;
          case /^advanced\..+\.include$/.test(name as string):
            // Check if all advanced filters are checked and set the include all checkbox value
            // if other checkboxes are checked
            setValue("selectAllAdvancedInclude", every(value.advanced, ["include", true]));
            break;
          case /^advanced\..+\.filter$/.test(name as string):
            // Check if all advanced filters are checked and set the filter all checkbox value
            // if other checkboxes are checked
            setValue("selectAllAdvancedFilter", every(value.advanced, ["filter", true]));
            break;
          case name === "selectAllAdvancedInclude":
            // Handle "select all" checkbox functionality for advanced filters - check all advanced filters "include" if "All" is selected
            handleSelectAllAdvanced({
              type: "include",
              value: value.selectAllAdvancedInclude as string,
              setValue,
              advancedFilters: filters?.optionalFilters?.advanced,
            });
            break;
          case name === "selectAllAdvancedFilter":
            // Handle "select all" checkbox functionality for advanced filters - check all advanced filters "filters" if "All" is selected
            handleSelectAllAdvanced({
              type: "filter",
              value: value.selectAllAdvancedFilter as string,
              setValue,
              advancedFilters: filters?.optionalFilters?.advanced,
            });
            break;
        }
      }
    });

    // Clean up the subscription when the component unmounts
    return () => subscription.unsubscribe();
  }, [watch, filters]);

  // Map the advanced filters into the table rows
  const advancedFiltersRows = createAdvancedFiltersRows({
    advancedFilters: filters?.optionalFilters?.advanced,
    defaultValues,
    advancedFilterVisibility,
    register,
    control,
  });

  /**
   * Closes the Save as template modal
   */
  function handleSaveAsTemplateModalClose() {
    setIsSaveAsTemplateModalOpen(false);
  }

  /**
   * Handles the submission of the Save As Template modal.
   * @param data            The data object that contains the form values
   * @param newTemplateName The new template name
   */
  function handleSaveAsTemplateModalSubmit(data: { newTemplateName: string }) {
    const formData = getValues();
    // get the data from the form to submit with the new template name
    handleSaveAsTemplateClick({ ...formData, templateName: data.newTemplateName });
    setIsSaveAsTemplateModalOpen(false);
  }

  return (
    <>
      <AnalyticsFiltersContainer>
        {filtersStatus !== cStatusType.Loading ? (
          <form>
            {/* Report header */}
            <AnalyticsFiltersHeaderContainer
              configName={configName}
              configID={configID}
              hasChanges={hasChanges}
              saveReportTemplateStatus={saveDocumentTypeReportTemplateStatus}
              runReportStatus={runDocumentTypeReportStatus}
              setIsDeleteReportTemplateModalOpen={setIsDeleteReportTemplateModalOpen}
              setIsSaveAsTemplateModalOpen={setIsSaveAsTemplateModalOpen}
              trigger={trigger}
              handleBackClick={handleBackClick}
              handleSubmit={handleSubmit}
              handleRunReportClick={handleRunReportClick}
              handleSaveTemplateClick={handleSaveTemplateClick}
            />

            {/* Render the form fields based on the filters */}
            <Div className={classNames(styles.formRow, styles.justified)} testId="document-class-filter">
              <label htmlFor="documentClass" className={styles.label}>
                Document class
              </label>
              <FormField
                name="documentClass"
                register={register}
                type={cFormFieldType.Buttons}
                options={documentClassOptions}
                defaultValue={filters?.documentClass?.value}
                testId="document-class-button-group"
              />
            </Div>

            <AnalyticsDateRangeFilter
              register={register}
              startDate={startDate}
              endDate={endDate}
              startDateError={errors.startDate}
              endDateError={errors.endDate}
            />

            <AnalyticsDateFilter
              register={register}
              dateFilterOptions={dateFilterOptions as IFieldOption[]}
              defaultValue={filters?.dateFilter?.value}
            />

            <Div className={classNames(styles.formRow, styles.justified)} testId="formats-filter">
              <label htmlFor="format" className={styles.label}>
                Format
              </label>
              <FormField
                name="format"
                register={register}
                type={cFormFieldType.Buttons}
                options={formatOptions}
                defaultValue={filters?.format.value}
                testId="formats-button-group"
              />
            </Div>
            <Div className={styles.formRowHeader}>
              <Typography variant="h4" spacing={{ mb: 0, pt: 4 }}>
                Optional filters
              </Typography>
            </Div>

            <AnalyticsDescriptionContainsFieldGroup register={register} />

            <Div className={styles.formRow} testId="document-data-filter">
              <Row>
                <Column sm={7}>
                  <label htmlFor="documentDataContains.value" className={styles.labelWithPopover}>
                    Document data contains{"\u00A0"}
                    <Popover
                      popoverPlacement={EPopoverPlacement.Top}
                      popoverContents="Include only items containing this text as part of their interview."
                      buttonContents={<Icon icon={EIcon.Help} color={cThemeColorType.Secondary} />}
                      buttonProps={{ variant: EButtonVariant.Link }}
                      className={styles.popover}
                    />
                  </label>
                  <FormField
                    name="documentDataContains.value"
                    register={register}
                    data-lpignore={true}
                    append={
                      <FormField
                        spacing={{ ml: 5 }}
                        name="documentDataContains.caseSensitive"
                        register={register}
                        type={cFormFieldType.Switch}
                        options={[
                          {
                            label: "Case sensitive",
                            value: "false",
                          },
                        ]}
                        testId="document-data-contains-case-sensitive-switch"
                      />
                    }
                    testId="document-data-contains-input"
                    fullWidth
                  />
                </Column>
              </Row>
            </Div>

            {allowDocumentStages && (
              <Div className={styles.formRow} testId="document-stage-filter">
                <label htmlFor="stages" className={styles.label}>
                  Document stages
                </label>
                <FormField
                  type={cFormFieldType.Checkbox}
                  name="selectAllStages"
                  options={[{ label: "All", value: "allStages" }]}
                  register={register}
                  spacing={{ mb: 6 }}
                />
                <Div className={stageFilterOptions && stageFilterOptions.length > maxOptions ? styles.scrollBox : ""}>
                  <FormField
                    type={cFormFieldType.Checkbox}
                    name="stages"
                    options={stageFilterOptions}
                    register={register}
                    testId="document-stage-options"
                  />
                </Div>
              </Div>
            )}

            <Div className={styles.formRow} testId="document-state-filter">
              <label htmlFor="states" className={styles.label}>
                Status
              </label>
              {states && (
                <FormField
                  type={cFormFieldType.Checkbox}
                  name="states"
                  options={stateFilterOptions}
                  testId="document-state-options"
                  register={register}
                />
              )}
              <Div spacing={{ pt: 5 }}>
                <FormField
                  type={cFormFieldType.Checkbox}
                  name="includeTerminated"
                  options={[{ label: "Include Terminated", value: "includeTerminated" }]}
                  register={register}
                />
              </Div>
            </Div>

            <AnalyticsOwnersFilter
              register={register}
              ownerFilterOptions={ownerFilterOptions}
              maxOptions={maxOptions}
            />

            <AnalyticsAdvancedFilters register={register} advancedFiltersRows={advancedFiltersRows} />
          </form>
        ) : (
          <Div p={{ base: 5 }} display={{ base: "flex" }} justifyContent={{ base: "center" }}>
            <Spinner />
          </Div>
        )}
      </AnalyticsFiltersContainer>
      <SaveAsTemplateModal
        isOpen={isSaveAsTemplateModalOpen}
        handleClose={handleSaveAsTemplateModalClose}
        onSubmit={handleSaveAsTemplateModalSubmit}
      />
    </>
  );
}

export default CustomExport;
